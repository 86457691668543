import React, { useEffect, useState } from 'react';
import { useQuery, gql } from "@apollo/client";
import { withRouter, Link } from 'react-router-dom';

import Map from '../../components/Map';

import { currencyFormat, concatAddress, getAmenityIcon } from '../../utils.js';
import ImageGalleryGrid from '../../components/ImageGalleryGrid/ImageGalleryGrid';
import ImageGallerySimple from '../../components/ImageGallerySimple/ImageGallerySimple';
import ReserveButton from '../../components/ReserveButton/ReserveButton';

import '../../App.css';
import './DetailOpportunityUnit.css';

const UNIT = gql`
  query GetUnitById($operationType: String, $propertyType: String, $city: String, $code: String, $unitId: ID!) {
    unit(operation_type: $operationType, property_type: $propertyType, city: $city, code: $code, id: $unitId) {
      id
      code
      number
      floor
      gallery_urls {
        url
        thumbnailUrl
        type
        tag
      }
      bedrooms
      bathrooms
      parking_spots
      price
      currency
      size
      status
      operationType
      unitType {
        id
        name
        display_name
        gallery_urls {
          url
          thumbnailUrl
          type
          tag
        }
      }
      group {
        id
        name
        display_name
        amenities       
        delivery_date
        address {
          line_1
          line_2
          city
          state
          country
          zip_code
        }
      }
    }
  }
`

const DetailOpportunityUnit = (props) => {
    const operationType = props.match.params.operation ? props.match.params.operation.toLowerCase() : "";
    const propertyType = props.match.params.type ? props.match.params.type.toLowerCase() : "";
    const city = props.match.params.city ? props.match.params.city.toLowerCase() : "";
    const code = props.match.params.code ? props.match.params.code : "";
    const unitId = props.match.params.unitId ? Number(props.match.params.unitId) : 0;

    const { loading, data, error } = useQuery(UNIT, {
        variables: {
            operationType: operationType,
            propertyType: propertyType,
            city: city,
            code: code,
            unitId: unitId
        }
    })

    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [zoom, setZoom] = useState(4);

    const handleAddressChange = async (address) => {
        fetch(`https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=l-zc7x-OI-k5ZMh3lWXjXyT2jAzH_OS6lqQZwAEXj7Y`, { method: "GET" })
            .then(response => response.json())
            .then(data => {
                if (data.items && data.items.length > 0) {
                    setLat(data.items[0].position.lat);
                    setLng(data.items[0].position.lng);
                    setZoom(13);
                }
                else {
                    setLat(20.69408);
                    setLng(-103.39115);
                    setZoom(13);
                }
            });
    }

    useEffect(() => {
        if (data) {
            handleAddressChange(concatAddress(data.unit.group.address));
        }
    }, [loading, data]);

    if (loading) return <div className="container-fluid"><div className='row'>Loading...</div></div>;
    if (error) return <div className="container-fluid"><div className='row'>Error! {error.message}</div></div>;

    return (
        <div className="container-fluid">
            {!loading &&
                <div style={{ margin: '0 20px' }}>
                    <div className='row'>
                        <h3><Link className='prefix-icon icon-arrow-left' to={`/properties`}>Detalles de la unidad</Link></h3>
                    </div>
                    <div className='row mobile-hide'>
                        <div style={{ padding: '20px 0px 10px', borderBottom: '1px solid #e2e5e4', margin: '0 30px 10px' }}>
                            <h3>Código: {data.unit.code}</h3>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                                <div className="prefix-icon icon-location">
                                    <p style={{ margin: '0' }}>{concatAddress(data.unit.group.address)}</p>
                                </div>
                                <div>
                                    <p className="prefix-icon icon-calendar">Entrega: {data.unit.group.delivery_date}</p>
                                </div>
                            </div>
                            <div className='right'>

                            </div>
                        </div>
                    </div>
                    <div className='row mobile-hide'>
                        <div style={{ padding: '20px 0px 10px', borderBottom: '1px solid #e2e5e4', margin: '0 30px 10px' }}>
                            <ImageGalleryGrid
                                mainItem={data.unit.gallery_urls.filter(i => i.tag === 'main')[0]}
                                items={data.unit.gallery_urls.filter(i => i.tag === 'photo')}
                            />
                        </div>
                    </div>

                    <div className='row mobile-display' style={{ marginTop: '20px', marginLeft: '-35px', marginRight: '-35px' }}>
                        <div style={{ borderBottom: '1px solid #e2e5e4' }}>
                            <ImageGallerySimple
                                items={
                                    [
                                        data.unit.gallery_urls.filter(i => i.tag === 'main')[0],
                                        ...data.unit.unitType.gallery_urls.filter(i => i.tag === 'photo')
                                    ]
                                }
                            />
                        </div>
                    </div>
                    <div className='row mobile-display'>
                        <div style={{ padding: '10px 0 20px', borderBottom: '1px solid #e2e5e4' }}>
                            <h3>Código: {data.unit.code}</h3>
                            <p className="prefix-icon icon-location">{concatAddress(data.unit.group.address)}</p>
                            <p className="prefix-icon icon-calendar">Entrega: {data.unit.group.delivery_date}</p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='features-container'>
                            <h4>Características generales de la unidad</h4>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '1fr 1fr' }}>
                                <div><p className="prefix-icon icon-land-size">{data.unit.size} m2</p></div>
                                <div><p className="prefix-icon icon-bedroom">{data.unit.bedrooms} dorm</p></div>
                                {data.unit.bathrooms === 1 &&
                                    <p className="prefix-icon icon-bathroom">1 baño</p>
                                }
                                {data.unit.bathrooms !== 1 &&
                                    <div><p className="prefix-icon icon-bathroom">{data.unit.bathrooms} baños</p></div>
                                }
                                {data.unit.parking_spots === 1 &&
                                    <p className="prefix-icon icon-car nowrap">1 parqueo</p>
                                }
                                {data.unit.parking_spots !== 1 &&
                                    <div><p className="prefix-icon icon-car nowrap">{data.unit.parking_spots} parqueos</p></div>
                                }
                            </div>
                            <div className='right'>
                                {(data.unit.status === 'Disponible') &&
                                    <ReserveButton page={'Opportunity Unit Detail Screen'} unit={data.unit} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='amenities-container'>
                            <h4>Amenidades y servicios del desarrollo</h4>
                            <div className='amenities'>
                                {data.unit.group.amenities.map(amenity => (
                                    <div key={amenity}>
                                        <p className='prefix-icon' style={{
                                            backgroundImage: `url(${getAmenityIcon(amenity)})`,
                                            backgroundSize: '30px 30px',
                                            backgroundRepeat: 'no-repeat'
                                        }}>
                                            {amenity}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='unit-container'>
                            <div className='unit-opportunity-details-container'>
                                <div className='left' style={{ border: '1px solid #e2e5e4', borderRadius: '15px', padding: '20px 10px' }}>
                                    <p><strong>Detalles de la unidad</strong></p>
                                    <p>Código: {data.unit.code}</p>
                                    <p>Nivel: {data.unit.floor}</p>
                                    <p>Medida: {data.unit.size}</p>
                                    <p>Dormitorios: {data.unit.bedrooms}</p>
                                    <p>Baños: {data.unit.bathrooms}</p>
                                    <p style={{ borderBottom: '1px solid #000' }}>Parqueos: {data.unit.parking_spots}</p>
                                    <div style={{ marginBottom: '20px' }}>
                                        <span>TOTAL </span>
                                        <span style={{ float: 'right' }}>{currencyFormat(data.unit.price, data.unit.currency)}</span>
                                    </div>

                                    <small>*Las unidades, el precio y las políticas comerciales están sujetos a confirmar disponibilidad y última lista de precios actualizada con el desarrollador, por lo que pueden modificarse y/o variar de lo aquí representado.</small>
                                </div>
                                {(data.unit.status === 'Disponible') &&
                                    <div>
                                        <p>Reserva esta unidad con Q2,000.00 mxn</p>
                                        <ReserveButton page={'Opportunity Unit Detail Screen'} unit={data.unit} style={{ width: '100%', marginTop: '10px' }} />
                                        <a href='https://meetings.hubspot.com/alina-patterson' target='_blank' rel='noopener noreferrer' className='btn btn-secundary' style={{ display: 'block', width: '100%', marginTop: '10px' }}>Quiero una videollamada</a>
                                        <a href={`https://wa.me/523318904974?text=Hola Monplaces, estoy interesado en la unidad ${data.unit.code} y quiero una cotización`} target='_blank' rel='noopener noreferrer' className='btn btn-secundary' style={{ display: 'block', width: '100%', marginTop: '10px' }}>Quiero una cotización</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='map-container'>
                            <h4>Mapa</h4>
                            <div style={{ margin: '30px -30px 10px' }}>
                                <Map
                                    lat={lat}
                                    lng={lng}
                                    zoom={zoom}
                                />
                            </div>
                            <div className='left' style={{ padding: '10px 0', textDecoration: 'underline' }}>
                                <a href={`https://maps.google.com/?q=${concatAddress(data.unit.group.address)}`} target='_blank' rel='noopener noreferrer'>
                                    {concatAddress(data.unit.group.address)}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='right footer-container'>
                            <a href={`https://wa.me/523318904974?text=Hola Monplaces, estoy interesado la unidad ${data.unit.code}`} target='_blank' rel='noopener noreferrer'>
                                ¿Tienes dudas? Contáctanos por WhatsApp&nbsp;&nbsp;
                                <img style={{ width: '36px', height: 'auto' }} src='/images/whatsapp.svg' alt='Whatsapp logo'></img>
                            </a>
                        </div>
                    </div>
                </div >
            }
        </div >
    );
}

export default withRouter(DetailOpportunityUnit);