import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, gql } from "@apollo/client";
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';

import ReserveButton from '../../components/ReserveButton/ReserveButton';

import { currencyFormat, concatAddress } from '../../utils.js';
import '../../App.css';
import './DetailUnit.css';

const UNIT = gql`
  query GetUnitById($operationType: String, $propertyType: String, $city: String, $group: String, $model: String, $code: String, $unitId: ID!) {
    unit(operation_type: $operationType, property_type: $propertyType, city: $city, group: $group, model: $model, code: $code, id: $unitId) {
      id
      code
      number
      floor
      bedrooms
      bathrooms
      parking_spots
      price
      currency
      size
      status
      operationType
      is_public
      unitType {
        id
        name
        display_name
        gallery_urls {
          url
          thumbnailUrl
          type
          tag
        }
        is_public
      }
      group {
        id
        name
        display_name
        amenities       
        delivery_date 
        is_public
        address {
          line_1
          line_2
          city
          state
          country
          zip_code
          location
        }
      }
    }
  }
`

const COMMERCIALPOLICIES = gql`
  query GetCommercialPoliciesByUnitId($code: String, $unitId: ID!) {
    unit(code: $code, id: $unitId) {
      commercialPolicies {
        id
        name
        display_name
        description
        discount
        initialPayment
        partialPayments
        lastPayment
        parent {
          id
        }
      }
      group {
        commercialPolicies {
          id
          name
          display_name
          description
          discount
          initialPayment
          partialPayments
          lastPayment
          units {
            id
          }
        }
      }
    }
  }
`

const customStyles = {
    option: (styles) => ({
        ...styles
    }),
    control: (styles) => ({
        ...styles,
        backgroundColor: '#F74C54'
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#FFFFFF'
    })
}

const DetailUnit = (props) => {
    const { t } = useTranslation();

    const operationType = props.match.params.operation ? props.match.params.operation.toLowerCase() : "";
    const propertyType = props.match.params.type ? props.match.params.type.toLowerCase() : "";
    const location = props.match.params.location ? props.match.params.location.toLowerCase() : "";
    const group = props.match.params.development ? props.match.params.development.toLowerCase() : "";
    const model = props.match.params.model ? props.match.params.model.toLowerCase() : "";
    const code = props.match.params.code ? props.match.params.code : "";
    const unitId = props.match.params.unitId ? Number(props.match.params.unitId) : 0;

    const { loading, data, error } = useQuery(UNIT, {
        variables: {
            operationType: operationType,
            propertyType: propertyType,
            city: location,
            group: group,
            model: model,
            code: code,
            unitId: unitId
        }
    })
    const [loadCommercialPolicies] = useLazyQuery(COMMERCIALPOLICIES, {
        variables: {
            code: props.match.params.code ? props.match.params.code : "",
            unitId: props.match.params.unitId ? Number(props.match.params.unitId) : 0
        },
        onCompleted: data => {
            if (data.unit) {
                // find overrided policies
                let overridedPolicies = data.unit.commercialPolicies.filter(p => p.parent).map(p => p.parent.id)

                // filter default policies only
                let groupPolicies = data.unit.group.commercialPolicies.filter(p => p.units.length === 0 && !overridedPolicies.includes(p.id))

                setCommercialPolicies([...data.unit.commercialPolicies, ...groupPolicies])
            } else {
                setCommercialPolicies(null)
            }
        }
    })

    const [selectedPolicyId, setSelectedPolicyId] = useState(null);
    const [selectedPolicy, setSelectedPolicy] = useState(null);

    const [commercialPolicies, setCommercialPolicies] = useState(null);

    const handleChangePolicy = (selectedOption) => {
        if (!selectedOption) {
            setSelectedPolicy(null);
            setSelectedPolicyId(null);
            return;
        }

        const policy = commercialPolicies.find(p => p.id === selectedOption.value)
        setSelectedPolicy(policy);
        setSelectedPolicyId(selectedOption);
    }

    const calculatePartialPrice = (price, discount, partial) => {
        return (price - (price * discount / 100)) * partial / 100;
    }

    useEffect(() => {
        loadCommercialPolicies()
    }, [loading, loadCommercialPolicies]);

    if (loading) return <div className="container-fluid"><div className='row'>{t('Common.loading')}</div></div>;
    if (error) return <div className="container-fluid"><div className='row'>Error! {error.message}</div></div>;

    return (
        <div className="container-fluid">
            <div style={{ margin: '0 20px' }}>
                <div className='row'>
                    <h3><Link className='prefix-icon icon-arrow-left' to={`/developments/${location}/${group}/${model}`}>Detalles del precio</Link></h3>
                </div>
                <div className='row mobile-hide'>
                    <div style={{ padding: '20px 50px 10px', borderBottom: '1px solid #e2e5e4', margin: '0 30px 10px' }}>
                        <h3>{data.unit.group.display_name}</h3>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                            <div className="prefix-icon icon-location">
                                <p style={{ margin: '0' }}>{concatAddress(data.unit.group.address)}</p>
                            </div>
                            <div>
                                <p className="prefix-icon icon-calendar">{t('Common.entrega')}: {data.unit.group.delivery_date}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mobile-display'>
                    <div style={{ padding: '10px 0 20px', borderBottom: '1px solid #e2e5e4' }}>
                        <h3>Tipo: {data.unit.unitType.display_name}</h3>
                        <p className="prefix-icon icon-location">{concatAddress(data.unit.group.address)}</p>
                        <p className="prefix-icon icon-calendar">{t('Common.entrega')}: {data.unit.group.delivery_date}</p>
                    </div>
                </div>

                <div className='row'>
                    <div className='unit-details-container'>
                        <div className='card-item'>
                            <div className='left-container'>
                                <div className='title' >
                                    <p style={{ fontWeight: 'normal' }}>Tipo: {data.unit.unitType.display_name}</p>
                                </div>
                                <div className='image'>
                                    <img src={data.unit.unitType.gallery_urls.filter(i => i.tag === 'layout')[0].url} alt={data.unit.code} style={{ position: 'center', width: '100%', height: 'auto', padding: '20px 0' }}></img>
                                </div>
                                <div>
                                    <p>Código: {data.unit.code}</p>
                                </div>
                            </div>
                            <div className='right-container outer' style={{ textAlign: 'left', padding: '5px 20px', border: 'none' }}>
                                <div className='middle'>
                                    <p className="subtitle">{currencyFormat(data.unit.price, data.unit.currency)}</p>
                                    <p className="prefix-icon icon-land-size">{data.unit.size} m2</p>
                                    <p className="prefix-icon icon-bedroom">{data.unit.bedrooms} dorm</p>
                                    {data.unit.bathrooms === 1 &&
                                        <p className="prefix-icon icon-bathroom">1 baño</p>
                                    }
                                    {data.unit.bathrooms !== 1 &&
                                        <div><p className="prefix-icon icon-bathroom">{data.unit.bathrooms} baños</p></div>
                                    }
                                    {data.unit.parking_spots === 1 &&
                                        <p className="prefix-icon icon-car nowrap">1 parqueo</p>
                                    }
                                    {data.unit.parking_spots !== 1 &&
                                        <div><p className="prefix-icon icon-car nowrap">{data.unit.parking_spots} parqueos</p></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='unit-details-container center' style={{ maxWidth: '600px' }}>
                        <div className='left' style={{ border: '1px solid #e2e5e4', borderRadius: '15px', padding: '20px 10px', marginBottom: '20px' }}>
                            <h4>Detalles de la unidad</h4>
                            <div style={{ margin: '20px 0' }}>
                                <p>Tipo: {data.unit.unitType.display_name}</p>
                                <p>Nivel: {data.unit.floor}</p>
                                <p>Medida: {data.unit.size}</p>
                                <p>Dormitorios: {data.unit.bedrooms}</p>
                                <p>Baños: {data.unit.bathrooms}</p>
                                <p>Parqueos: {data.unit.parking_spots}</p>
                            </div>
                            {(!commercialPolicies || commercialPolicies.length === 0) &&
                                <div style={{ borderTop: '1px solid #000', margin: '20px 0' }}>
                                    <span>Total </span>
                                    <span style={{ float: 'right' }}>{currencyFormat(data.unit.price, data.unit.currency)}</span>
                                </div >
                            }
                            {
                                (commercialPolicies && commercialPolicies.length > 0) &&
                                <div style={{ marginBottom: '20px' }}>
                                    <div style={{ borderTop: '1px solid #000', margin: '20px 0', paddingTop: '10px' }}>
                                        <span>Subtotal </span>
                                        <span style={{ float: 'right' }}>{currencyFormat(data.unit.price, data.unit.currency)}</span>
                                    </div >

                                    <Select
                                        style={{ margin: '15px auto' }}
                                        placeholder='Selecciona la política comercial'
                                        styles={customStyles}
                                        value={selectedPolicyId}
                                        options={commercialPolicies.sort((a, b) => b.discount - a.discount).map(policy => { return { label: policy.display_name, value: policy.id } })}
                                        onChange={(policy) => handleChangePolicy(policy)}
                                    />

                                    {
                                        selectedPolicy &&
                                        <div style={{ margin: '20px 0' }}>
                                            <div style={{ margin: '0 0 10px' }}>
                                                <span>Descuento: {selectedPolicy.discount}%</span>
                                                <span style={{ float: 'right' }}>(-{currencyFormat(data.unit.price * selectedPolicy.discount / 100, data.unit.currency)})</span>
                                            </div>

                                            <div style={{ margin: '0 0 10px' }}>
                                                <span>Pago inicial: {selectedPolicy.initialPayment}%</span>
                                                <span style={{ float: 'right' }}>{currencyFormat(calculatePartialPrice(data.unit.price, selectedPolicy.discount, selectedPolicy.initialPayment), data.unit.currency)}</span>
                                            </div>

                                            <div style={{ margin: '0 0 10px' }}>
                                                <span>Mensualidades: {selectedPolicy.partialPayments}%</span>
                                                <span style={{ float: 'right' }}>{currencyFormat(calculatePartialPrice(data.unit.price, selectedPolicy.discount, selectedPolicy.partialPayments), data.unit.currency)}</span>
                                            </div>

                                            <div style={{ margin: '0 0 10px' }}>
                                                <span>Pago final: {selectedPolicy.lastPayment}%</span>
                                                <span style={{ float: 'right' }}>{currencyFormat(calculatePartialPrice(data.unit.price, selectedPolicy.discount, selectedPolicy.lastPayment), data.unit.currency)}</span>
                                            </div>

                                            <div style={{ borderTop: '1px solid #000', margin: '20px 0', paddingTop: '10px' }}>
                                                <span>TOTAL </span>
                                                <span style={{ float: 'right' }}>{currencyFormat((data.unit.price - (data.unit.price * selectedPolicy.discount / 100)), data.unit.currency)}</span>
                                            </div>
                                        </div>
                                    }
                                </div >
                            }
                            <small>*Las unidades, el precio y las políticas comerciales están sujetos a confirmar disponibilidad y última lista de precios actualizada con el desarrollador, por lo que pueden modificarse y/o variar de lo aquí representado.</small>
                        </div >
                        {(data.unit.status === 'Disponible') &&
                            <div className='center'>
                                <p style={{ margin: '20px 0 0', fontSize: '16px' }}>Reserva esta unidad con Q2,000.00 mxn</p>
                                <p>100% reembolsable</p>
                                <ReserveButton page={'Unit Detail Screen'} unit={data.unit} commercialPolicy={selectedPolicy} style={{ width: '100%', marginTop: '10px' }} />
                                <a href='https://meetings.hubspot.com/alina-patterson' target='_blank' rel='noopener noreferrer' className='btn btn-secundary' style={{ display: 'block', width: '100%', marginTop: '10px' }}>Quiero una videollamada</a>
                                <a href={`https://wa.me/523318904974?text=Hola Monplaces, estoy interesado en la unidad ${data.unit.code} y quiero una cotización`} target='_blank' rel='noopener noreferrer' className='btn btn-secundary' style={{ display: 'block', width: '100%', marginTop: '10px' }}>Quiero una cotización</a>
                            </div>
                        }

                        <div className='center footer-container'>
                            <a href={`https://wa.me/523318904974?text=Hola Monplaces, estoy interesado la unidad ${data.unit.display_name} del desarrollo ${data.unit.group.display_name}`} target='_blank' rel='noopener noreferrer'>
                                ¿Tienes dudas? Contáctanos por WhatsApp&nbsp;&nbsp;
                                <img style={{ width: '36px', height: 'auto' }} src='/images/whatsapp.svg' alt='Whatsapp logo'></img>
                            </a>
                        </div>
                    </div >
                </div >
            </div >
        </div >
    );
}

export default withRouter(DetailUnit);