import React, { useState, useEffect, useCallback } from 'react';
import { useQuery, useLazyQuery, gql } from "@apollo/client";
import { withRouter, Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import ReactGA from 'react-ga4';
import { track as trackAmplitude } from '@amplitude/analytics-browser';

import { useTranslation } from 'react-i18next';

import { currencyFormat, concatAddress, toKebab, minValue, getAmenityIcon } from '../../utils.js';

import ImageGalleryGrid from '../../components/ImageGalleryGrid/ImageGalleryGrid';
import ImageGallerySimple from '../../components/ImageGallerySimple/ImageGallerySimple';
import ReserveButton from '../../components/ReserveButton/ReserveButton';
import AuthLinkDownload from '../../components/AuthLinkDownload/AuthLinkDownload.js';

import '../../App.css';
import './DetailUnitType.css';

const UNITTYPE = gql`
  query GetUnitTypeById($operationType: String, $propertyType: String, $city: String, $group: String, $model: String, $unitTypeId: ID!) {
    unitType(operation_type: $operationType, property_type: $propertyType, city: $city, group: $group, model: $model, id: $unitTypeId) {
      id
      name
      display_name
      gallery_urls {
        url
        thumbnailUrl
        type
        tag
      }
      type
      units {
        id
        code
        number
        floor
        bedrooms
        bathrooms
        parking_spots
        price
        currency
        size
        status
        operationType
        group {
            id
            name
            display_name
            badge_image_url
            address {
                line_1
                line_2
                city
                state
                country
                zip_code
                location
            }
        }
        unitType {
            name
            display_name
            type
        }
      }
      group {
        id
        name
        display_name
        badge_image_url
        brochure_url
        price_list_url
        commercial_policies_url
        amenities      
        address {
          line_1
          line_2
          city
          state
          country
          zip_code
          location
        }
        delivery_date
      }
    }
  }
`

const COMMERCIALPOLICIES = gql`
  query GetCommercialPoliciesByUnitId($unitId: ID!) {
    unit(id: $unitId) {
      commercialPolicies {
        id
        name
        display_name
        description
        discount
        initialPayment
        partialPayments
        lastPayment
        parent {
          id
        }
      }
      group {
        commercialPolicies {
          id
          name
          display_name
          description
          discount
          initialPayment
          partialPayments
          lastPayment
          units {
            id
          }
        }
      }
    }
  }
`

const customStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: '#465C66',
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? 'rgba(41, 171, 184, 0.18)'
                : isFocused
                    ? 'rgba(41, 171, 184, 0.18)'
                    : undefined,
        padding: '5px',
    }),
    control: (styles) => ({
        ...styles,
        border: 'none',
        minHeight: '30px',
        marginBottom: '5px',
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#000'
    })
}

const CaretDownIcon = () => {
    return <svg width="30" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.86873 4.79195C1.56248 5.11014 1.56248 5.62312 1.86873 5.94131L7.06247 11.3374C7.30622 11.5907 7.69997 11.5907 7.94372 11.3374L13.1375 5.94131C13.4437 5.62312 13.4437 5.11014 13.1375 4.79195C12.8174 4.78546 12.7182 4.78546 12.0312 4.79195L7.46024 4.79195L2.96873 4.78546C2.30151 4.79195 2.20231 4.79195 1.86873 4.79195Z" fill="#F74750" />
    </svg>
}

const IndicatorsContainer = props => {
    return (
        <components.IndicatorsContainer {...props}>
            <CaretDownIcon />
        </components.IndicatorsContainer>
    );
};

let floorOptions = [];
let unitOptions = [];

const DetailUnitType = (props) => {
    const { t } = useTranslation();

    const operationType = props.match.params.operation ? props.match.params.operation.toLowerCase() : "";
    const propertyType = props.match.params.type ? props.match.params.type.toLowerCase() : "";
    const location = props.match.params.location ? props.match.params.location.toLowerCase() : "";
    const group = props.match.params.development ? props.match.params.development.toLowerCase() : "";
    const model = props.match.params.model ? props.match.params.model.toLowerCase() : "";
    const unitTypeId = props.match.params.unitTypeId ? Number(props.match.params.unitTypeId) : 0;
    const developmentId = props.match.params.developmentId ? Number(props.match.params.developmentId) : 0;

    const { loading, data, error } = useQuery(UNITTYPE, {
        variables: {
            operationType: operationType,
            propertyType: propertyType,
            city: location,
            group: group,
            model: model,
            unitTypeId: unitTypeId
        }
    })

    const [loadCommercialPolicies] = useLazyQuery(COMMERCIALPOLICIES, {
        onCompleted: data => {
            if (data.unit) {
                // find overrided policies
                let overridedPolicies = data.unit.commercialPolicies.filter(p => p.parent).map(p => p.parent.id)

                // filter default policies only
                let groupPolicies = data.unit.group.commercialPolicies.filter(p => p.units.length === 0 && !overridedPolicies.includes(p.id))

                setCommercialPolicies([...data.unit.commercialPolicies, ...groupPolicies])
            } else {
                setCommercialPolicies(null)
            }
        }
    })

    const [unitType, setUnitType] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const [selectedPolicyId, setSelectedPolicyId] = useState(null);
    const [commercialPolicies, setCommercialPolicies] = useState(null);
    const [bedrooms, setBedrooms] = useState(null)
    const [floor, setFloor] = useState(null)

    const eventTrack = (category, action, label) => {
        // console.log("GA event:", category, ":", action, ":", label);
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        });

        trackAmplitude(category, {
            action: action,
            label: label
        });
    }

    const handleChange = useCallback((unit) => {
        eventTrack("UnitType Detail Screen", "Select Unit", "Selector");
        setSelectedUnit(unit);
        setSelectedPolicy(null);
        setSelectedPolicyId(null);

        if (unit) {
            loadCommercialPolicies({ variables: { unitId: unit.id } })
        } else {
            setCommercialPolicies(null)
        }
    }, [loadCommercialPolicies])

    const handleChangePolicy = (selectedOption) => {
        eventTrack("UnitType Detail Screen", "Select Policy", "Selector");

        if (!selectedOption) {
            setSelectedPolicy(null);
            setSelectedPolicyId(null);
            return;
        }

        const policy = commercialPolicies.find(p => p.id === selectedOption.value)
        setSelectedPolicy(policy);
        setSelectedPolicyId(selectedOption);
    }

    const navigateToUnit = (unit) => {
        props.history.push(`/${toKebab(unit.operationType)}/${toKebab(unit.unitType.type)}/${toKebab(unit.group.address.location)}/${unit.group.name}/${unit.unitType.name}/${unit.code}`);
    }

    const calculatePartialPrice = (price, discount, partial) => {
        return (price - (price * discount / 100)) * partial / 100;
    }

    useEffect(() => {
        if (data) {
            const unitTypeFiltered = {
                id: data.unitType.id,
                name: data.unitType.name,
                display_name: data.unitType.display_name,
                main_image: data.unitType.gallery_urls.filter(i => i.tag === 'main')[0],
                layout_image: data.unitType.gallery_urls.filter(i => i.tag === 'layout')[0],
                photo_image: data.unitType.gallery_urls.filter(i => i.tag === 'photo'),
                type: data.unitType.type,
                units: data.unitType.units.filter(unit =>
                    unit.status.toLowerCase() === 'disponible'
                    && (!operationType || (operationType === 'compra' && (unit.operationType.toLowerCase() === 'venta' || unit.operationType.toLowerCase() === 'preventa')) || unit.operationType.toLowerCase() === operationType)
                    && (!bedrooms || unit.bedrooms >= bedrooms)
                    && (!floor || unit.floor === floor)
                ),
                maxFloor: Math.max(...[].concat(...data.unitType.units.filter(unit =>
                    unit.status.toLowerCase() === 'disponible'
                    && (!operationType || (operationType === 'compra' && (unit.operationType.toLowerCase() === 'venta' || unit.operationType.toLowerCase() === 'preventa')) || unit.operationType.toLowerCase() === operationType)
                ).map(unit => unit.floor)
                )),
                group: data.unitType.group
            };

            setUnitType(unitTypeFiltered);

            unitOptions = unitTypeFiltered.units.map(unit => { return { value: unit.id, label: `${unit.unitType.display_name} ${unit.number} - ${currencyFormat(unit.price, unit.currency)}` } });

            let defaultUnit = unitTypeFiltered.units ? unitTypeFiltered.units[0] : null;
            handleChange(defaultUnit);

            floorOptions = [
                { value: '', label: t('Common.seleccione') },
                { value: '0', label: 'PB' }
            ]
            for (let i = 0; i < unitTypeFiltered.maxFloor; i++) {
                floorOptions.push({ value: `${i + 1}`, label: `${i + 1}` })
            }
        }
    }, [loading, data, operationType, bedrooms, floor, handleChange, loadCommercialPolicies]);

    if (loading) return <div className="container-fluid"><div className='row'>{t('Common.loading')}</div></div>;
    if (error) return <div className="container-fluid"><div className='row'>Error! {error.message}</div></div>;

    return (
        <div className="container-fluid">
            <div style={{ margin: '0 20px' }}>
                <div className='row unit-type-details-title'>
                    {(unitTypeId !== 0) &&
                        <h3><Link className='prefix-icon icon-arrow-left' to={`/developments/${developmentId}`}>{t('UnitDetails.detallesUnidad')}</Link></h3>
                    }
                    {(unitTypeId === 0 && !operationType && !propertyType) &&
                        <h3><Link className='prefix-icon icon-arrow-left' to={`/developments/${location}/${group}`}>{t('UnitDetails.detallesUnidad')}</Link></h3>
                    }
                    {(unitTypeId === 0 && operationType && propertyType) &&
                        <h3><Link className='prefix-icon icon-arrow-left' to={`/${operationType}/${propertyType}/${location}/${group}`}>{t('UnitDetails.detallesUnidad')}</Link></h3>
                    }
                </div>
                {unitType &&
                    <>
                        <div className='row mobile-hide'>
                            <div style={{ padding: '20px 0px 10px', borderBottom: '1px solid #e2e5e4', margin: '0 30px 10px' }}>
                                <h3>
                                    {unitType.group.display_name}
                                    {
                                        unitType.group.badge_image_url && <img style={{ width: '80px', marginLeft: '30px' }} src={unitType.group.badge_image_url} alt="badge" />
                                    }
                                </h3>
                                <h3>{t('Common.tipo')}: {unitType.display_name}</h3>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                                    <div className="prefix-icon icon-location">
                                        <p style={{ margin: '0' }}>{concatAddress(unitType.group.address)}</p>
                                    </div>
                                    <div>
                                        <p className="prefix-icon icon-calendar">{t('Common.entrega')}: {unitType.group.delivery_date}</p>
                                    </div>
                                </div>
                                <div className='right'>

                                </div>
                            </div>
                        </div>
                        <div className='mobile-hide'>
                            <div className='row'>
                                <div style={{ padding: '20px 0px 10px', borderBottom: '1px solid #e2e5e4', margin: '0 30px 10px' }}>
                                    <ImageGalleryGrid
                                        mainItem={unitType.main_image}
                                        items={unitType.photo_image}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='mobile-display'>
                            <div className='row' style={{ marginTop: '20px', marginLeft: '-35px', marginRight: '-35px' }}>
                                <div style={{ borderBottom: '1px solid #e2e5e4' }}>
                                    <ImageGallerySimple
                                        items={
                                            [
                                                unitType.main_image,
                                                ...unitType.photo_image
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div style={{ padding: '10px 0 20px', borderBottom: '1px solid #e2e5e4' }}>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 80px', columnGap: '10px', alignItems: 'center' }}>
                                        <div>
                                            <h3>{unitType.group.display_name}</h3>
                                        </div>
                                        <div>
                                            {
                                                unitType.group.badge_image_url && <img style={{ width: '80px' }} src={unitType.group.badge_image_url} alt="badge" />
                                            }
                                        </div>
                                    </div>
                                    <h3>{t('Common.tipo')}: {unitType.display_name}</h3>
                                    <p className="prefix-icon icon-location">{concatAddress(unitType.group.address)}</p>
                                    <p className="prefix-icon icon-calendar">{t('Common.entrega')}: {unitType.group.delivery_date}</p>
                                </div>
                            </div>
                        </div>

                        <div className='unit-type-details'>
                            <div className='info-container'>
                                <div className='links-container'>
                                    {unitType.group.brochure_url &&
                                        <div className='left' style={{ padding: '30px 0 0' }}>
                                            <AuthLinkDownload
                                                page={'UnitType Detail Screen'}
                                                action={'Download Brochure Link'}
                                                fileUrl={unitType.group.brochure_url}
                                                fileName={'brochure.pdf'}
                                                className='btn btn-secundary'
                                                style={{ display: "block", marginBottom: "15px", width: "250px" }}>
                                                {t('Common.descargarBrochure')}
                                            </AuthLinkDownload>
                                        </div>
                                    }
                                    {unitType.group.price_list_url &&
                                        <div className='left' style={{ padding: '0' }}>
                                            <AuthLinkDownload
                                                page={'UnitType Detail Screen'}
                                                action={'Download PriceList Link'}
                                                fileUrl={unitType.group.price_list_url}
                                                fileName={'lista-precios.pdf'}
                                                className='btn btn-secundary'
                                                style={{ display: "block", marginBottom: "15px", width: "250px" }}>
                                                {t('Common.descargarPrecios')}
                                            </AuthLinkDownload>
                                        </div>
                                    }
                                    {unitType.group.commercial_policies_url &&
                                        <div className='left' style={{ padding: '0' }}>
                                            <AuthLinkDownload
                                                page={'UnitType Detail Screen'}
                                                action={'Download Policies Link'}
                                                fileUrl={unitType.group.commercial_policies_url}
                                                fileName={'politicas-comerciales.png'}
                                                className='btn btn-secundary'
                                                style={{ display: "block", marginBottom: "15px", width: "250px" }}>
                                                {t('Common.descargarPoliticas')}
                                            </AuthLinkDownload>
                                        </div>
                                    }
                                </div>

                                <div className='features-container'>
                                    <h4>{t('UnitDetails.caracteristicas')}</h4>
                                    <div className='features'>
                                        <div><p className="prefix-icon icon-land-size">{minValue(unitType.units, 'size')} m2</p></div>
                                        < div > <p className="prefix-icon icon-bedroom">{minValue(unitType.units, 'bedrooms')} dorm</p></div>
                                        {minValue(unitType.units, 'bathrooms') === 1 &&
                                            < p className="prefix-icon icon-bathroom" > 1 baño</p>
                                        }
                                        {minValue(unitType.units, 'bathrooms') !== 1 &&
                                            < p className="prefix-icon icon-bathroom" > {minValue(unitType.units, 'bathrooms')} baños</p>
                                        }
                                        {minValue(unitType.units, 'parking_spots') === 1 &&
                                            <p className="prefix-icon icon-car nowrap">1 parqueo</p>
                                        }
                                        {minValue(unitType.units, 'parking_spots') !== 1 &&
                                            < p className="prefix-icon icon-car nowrap" > {minValue(unitType.units, 'parking_spots')} parqueos</p>
                                        }
                                    </div>
                                </div>

                                <div className='amenities-container'>
                                    <h4>{t('UnitDetails.amenidades')}</h4>
                                    <div className='amenities'>
                                        {unitType.group.amenities.map(amenity => (
                                            <div key={amenity}>
                                                <p className='prefix-icon' style={{
                                                    backgroundImage: `url(${getAmenityIcon(amenity)})`,
                                                    backgroundSize: '30px 30px',
                                                    backgroundRepeat: 'no-repeat'
                                                }}>
                                                    {amenity}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className='row'>
                                    <h4>Precio</h4>
                                    <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
                                        <Select
                                            placeholder='Dormitorios'
                                            options={[
                                                { value: '', label: 'Seleccione...' },
                                                { value: '1', label: '1' },
                                                { value: '2', label: '2' },
                                                { value: '3', label: '3' },
                                                { value: '4', label: '4' },
                                                { value: '5', label: '5+' }
                                            ]}
                                            onChange={(type) => setBedrooms(type.value)}
                                            styles={customStyles}
                                            components={{ IndicatorsContainer: IndicatorsContainer }}
                                        />
                                        <Select
                                            placeholder='Nivel'
                                            options={floorOptions}
                                            onChange={(floor) => setFloor(floor.value)}
                                            styles={customStyles}
                                            components={{ IndicatorsContainer: IndicatorsContainer }}
                                        />
                                        <div style={{ gridColumn: '1/3' }}>
                                            {unitType.units.some(unit => unit.status === 'Disponible') &&
                                                <Select
                                                    placeholder='Unidad'
                                                    options={unitOptions}
                                                    onChange={(unit) => handleChange(unitType.units.filter(u => u.id == unit.value)[0])}
                                                    defaultValue={selectedUnit.id || 'Unidad'}
                                                    styles={customStyles}
                                                    components={{ IndicatorsContainer: IndicatorsContainer }}
                                                />
                                            }
                                            {!unitType.units.some(unit => unit.status === 'Disponible') &&
                                                <>
                                                    No hay unidades disponibles
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {selectedUnit &&
                                    <>
                                        <div className='row mobile-hide'>
                                            <div className='unit-selection-container'>
                                                <div className='units cards-1' style={{ maxHeight: 'calc(100vh - 90px)', overflowY: 'auto' }}>
                                                    <div className='card-item'>
                                                        <div className='left-container'>
                                                            <div className='title'>
                                                                <p>{unitType.display_name} {selectedUnit.number}</p>
                                                            </div>
                                                            <div className='image'>
                                                                <img src={unitType.layout_image.url} alt={selectedUnit.code} style={{ position: 'center' }}></img>
                                                            </div>
                                                            <div>
                                                                <label className="badge">{selectedUnit.status}</label>
                                                                <p>Código: {selectedUnit.code}</p>
                                                            </div>
                                                        </div>
                                                        <div className='right-container outer'>
                                                            <div className='middle'>
                                                                <p className="subtitle">{currencyFormat(selectedUnit.price, selectedUnit.currency)}</p>
                                                                <p className="prefix-icon icon-land-size">{selectedUnit.size} m2</p>
                                                                <p className="prefix-icon icon-bedroom">{selectedUnit.bedrooms} dorm</p>
                                                                {selectedUnit.bathrooms === 1 &&
                                                                    <p className="prefix-icon icon-bathroom">1 baño</p>
                                                                }
                                                                {selectedUnit.bathrooms !== 1 &&
                                                                    <p className="prefix-icon icon-bathroom">{selectedUnit.bathrooms} baños</p>
                                                                }
                                                                {selectedUnit.parking_spots === 1 &&
                                                                    <p className="prefix-icon icon-car nowrap">1 parqueo</p>
                                                                }
                                                                {selectedUnit.parking_spots !== 1 &&
                                                                    <p className="prefix-icon icon-car nowrap">{selectedUnit.parking_spots} parqueos</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mobile-display'>
                                            <div className='unit-selection-container'>
                                                <div className='units cards-1' style={{ maxHeight: 'calc(100vh - 90px)', overflowY: 'auto' }}>
                                                    <div className='card-item' onClick={() => navigateToUnit(selectedUnit)}>
                                                        <div className='left-container'>
                                                            <div className='title'>
                                                                <p>{unitType.display_name} {selectedUnit.number}</p>
                                                            </div>
                                                            <div className='image'>
                                                                <img src={unitType.layout_image.url} alt={selectedUnit.code} style={{ position: 'center' }}></img>
                                                            </div>
                                                            <div>
                                                                <label className="badge">{selectedUnit.status}</label>
                                                                <p style={{ marginTop: '15px' }}>Código: {selectedUnit.code}</p>
                                                            </div>
                                                        </div>
                                                        <div className='right-container outer'>
                                                            <div className='middle'>
                                                                <p className="subtitle">{currencyFormat(selectedUnit.price, selectedUnit.currency)}</p>
                                                                <p className="prefix-icon icon-land-size">{selectedUnit.size} m2</p>
                                                                <p className="prefix-icon icon-bedroom">{selectedUnit.bedrooms} dorm</p>
                                                                {selectedUnit.bathrooms === 1 &&
                                                                    <p className="prefix-icon icon-bathroom">1 baño</p>
                                                                }
                                                                {selectedUnit.bathrooms !== 1 &&
                                                                    <p className="prefix-icon icon-bathroom">{selectedUnit.bathrooms} baños</p>
                                                                }
                                                                {selectedUnit.parking_spots === 1 &&
                                                                    <p className="prefix-icon icon-car nowrap">1 parqueo</p>
                                                                }
                                                                {selectedUnit.parking_spots !== 1 &&
                                                                    <p className="prefix-icon icon-car nowrap">{selectedUnit.parking_spots} parqueos</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                            <div className='unit-container'>
                                {selectedUnit &&
                                    <div className='mobile-hide'>
                                        <div style={{ margin: '30px auto 0', width: '500px' }}>
                                            <div className='left' style={{ border: '1px solid #e2e5e4', borderRadius: '15px', padding: '20px 10px' }}>
                                                <h4>Detalles de la unidad</h4>
                                                <div style={{ margin: '20px 0' }}>
                                                    <p>{t('Common.tipo')}: {unitType.display_name}</p>
                                                    <p>{t('Common.nivel')}: {selectedUnit.floor}</p>
                                                    <p>{t('Common.medida')}: {selectedUnit.size}</p>
                                                    <p>{t('Common.recamaras')}: {selectedUnit.bedrooms}</p>
                                                    <p>{t('Common.estacionamientos')}: {selectedUnit.bathrooms}</p>
                                                    <p>{t('Common.entrega')}: {selectedUnit.parking_spots}</p>
                                                </div>
                                                {(!commercialPolicies || commercialPolicies.length === 0) &&
                                                    <div style={{ borderTop: '1px solid #000', margin: '20px 0' }}>
                                                        <span>Total </span>
                                                        <span style={{ float: 'right' }}>{currencyFormat(selectedUnit.price, selectedUnit.currency)}</span>
                                                    </div>
                                                }
                                                {(commercialPolicies && commercialPolicies.length > 0) &&
                                                    <div style={{ marginBottom: '20px' }}>
                                                        <div style={{ borderTop: '1px solid #000', margin: '20px 0', paddingTop: '10px' }}>
                                                            <span>Subtotal </span>
                                                            <span style={{ float: 'right' }}>{currencyFormat(selectedUnit.price, selectedUnit.currency)}</span>
                                                        </div>

                                                        <div>
                                                            <Select
                                                                style={{ margin: '15px auto' }}
                                                                className="select"
                                                                placeholder='Selecciona la política comercial'
                                                                styles={customStyles}
                                                                isSearchable={true}
                                                                value={selectedPolicyId}
                                                                options={commercialPolicies.sort((a, b) => b.discount - a.discount).map(policy => { return { label: policy.display_name, value: policy.id } })}
                                                                onChange={(policy) => handleChangePolicy(policy)}
                                                            />
                                                        </div>

                                                        {selectedPolicy &&
                                                            <div style={{ margin: '20px 0' }}>
                                                                <div style={{ margin: '0 0 10px' }}>
                                                                    <span>Descuento: {selectedPolicy.discount}%</span>
                                                                    <span style={{ float: 'right' }}>(-{currencyFormat(selectedUnit.price * selectedPolicy.discount / 100, selectedUnit.currency)})</span>
                                                                </div>

                                                                <div style={{ margin: '0 0 10px' }}>
                                                                    <span>Pago inicial: {selectedPolicy.initialPayment}%</span>
                                                                    <span style={{ float: 'right' }}>{currencyFormat(calculatePartialPrice(selectedUnit.price, selectedPolicy.discount, selectedPolicy.initialPayment), selectedUnit.currency)}</span>
                                                                </div>

                                                                <div style={{ margin: '0 0 10px' }}>
                                                                    <span>Mensualidades: {selectedPolicy.partialPayments}%</span>
                                                                    <span style={{ float: 'right' }}>{currencyFormat(calculatePartialPrice(selectedUnit.price, selectedPolicy.discount, selectedPolicy.partialPayments), selectedUnit.currency)}</span>
                                                                </div>

                                                                <div style={{ margin: '0 0 10px' }}>
                                                                    <span>Pago final: {selectedPolicy.lastPayment}%</span>
                                                                    <span style={{ float: 'right' }}>{currencyFormat(calculatePartialPrice(selectedUnit.price, selectedPolicy.discount, selectedPolicy.lastPayment), selectedUnit.currency)}</span>
                                                                </div>

                                                                <div style={{ borderTop: '1px solid #000', margin: '20px 0', paddingTop: '10px' }}>
                                                                    <span>TOTAL </span>
                                                                    <span style={{ float: 'right' }}>{currencyFormat((selectedUnit.price - (selectedUnit.price * selectedPolicy.discount / 100)), selectedUnit.currency)}</span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <small>*Las unidades, el precio y las políticas comerciales están sujetos a confirmar disponibilidad y última lista de precios actualizada con el desarrollador, por lo que pueden modificarse y/o variar de lo aquí representado.</small>
                                            </div>
                                            {(selectedUnit && selectedUnit.status === 'Disponible') &&
                                                <div className='center'>
                                                    <p style={{ margin: '20px 0 0', fontSize: '18px' }}>Reserva esta unidad con Q2,000.00 mxn</p>
                                                    <p>100% reembolsable</p>
                                                    <ReserveButton page={'UnitType Detail Screen'} unit={selectedUnit} commercialPolicy={selectedPolicy} style={{ width: '100%', marginTop: '10px' }} />
                                                    <a href='https://meetings.hubspot.com/alina-patterson' target='_blank' rel='noopener noreferrer' className='btn btn-secundary' style={{ display: 'block', width: '100%', marginTop: '10px' }}>Quiero una videollamada</a>
                                                    <a href={`https://wa.me/523318904974?text=Hola Monplaces, estoy interesado en la unidad ${selectedUnit.code} y quiero una cotización`} target='_blank' rel='noopener noreferrer' className='btn btn-secundary' style={{ display: 'block', width: '100%', marginTop: '10px' }}>Quiero una cotización</a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                <div className='center footer-container'>
                                    <a href={`https://wa.me/523318904974?text=Hola Monplaces, estoy interesado la unidad ${unitType.display_name} del desarrollo ${unitType.group.display_name}`} target='_blank' rel='noopener noreferrer'>
                                        ¿Tienes dudas? Contáctanos por WhatsApp&nbsp;&nbsp;
                                        <img style={{ width: '36px', height: 'auto' }} src='/images/whatsapp.svg' alt='Whatsapp logo'></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default withRouter(DetailUnitType);