import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery, gql } from "@apollo/client";
import qs from "qs"
import ReactGA from 'react-ga4';
import { track as trackAmplitude } from '@amplitude/analytics-browser';

import { useTranslation } from 'react-i18next';

import Select, { components } from 'react-select';
// import AsyncSelect from 'react-select/async';
import Slider from 'rc-slider';
import "rc-slider/assets/index.css";

import { currencyFormat, concatAddress, minValue, maxValue, dateStringFormat } from '../../utils.js';
import ImageGallerySimple from '../../components/ImageGallerySimple/ImageGallerySimple';

import '../../App.css';
import './ListDevelopment.css';

const LIST_DEVELOPMENTS = gql`
  query AllDevelopments($operationType: String, $propertyType: String, $city: String) {
    groups(operation_type: $operationType, property_type: $propertyType, city: $city) {
        id
        name
        display_name
        delivery_date
        badge_image_url
        gallery_urls {
          url
          thumbnailUrl
          type
          tag
        }
        is_published
        sold_out
        landing_page_url
        address {
          line_1
          line_2
          city
          state
          country
          zip_code
          location
        }
        unitTypes {
            id
            type
            name
            display_name
            gallery_urls {
                url
                type
                tag
            }
            units {
                id
                bedrooms
                bathrooms
                parking_spots
                price
                currency
                size
                status
                operationType
            }
        }
    }
  }
`

const locationOptions = [
    { value: 'guatemala', label: 'Cdad. de Guatemala' },
    { value: 'muxbal', label: 'San Jorge Muxbal' },
];

const propertyTypeOptions = [
    { value: 'casa', label: 'Casa' },
    { value: 'departamento', label: 'Apartamento' },
    { value: 'terreno', label: 'Terreno' },
];

const operationTypeOptions = [
    { value: 'compra', label: 'Compra' },
    { value: 'renta', label: 'Renta' },
];

const propertyAgeOptions = [
    { value: 'preventa', label: 'Preventa' },
    { value: 'venta', label: 'Nuevo' },
];

const customStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: '#465C66',
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? 'rgba(41, 171, 184, 0.18)'
                : isFocused
                    ? 'rgba(41, 171, 184, 0.18)'
                    : undefined,
        padding: '10px',
    }),
    control: (styles) => ({
        ...styles,
        padding: '5px',
        border: 'none',
        marginBottom: '5px',
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#000'
    })
}

// const SearchIcon = () => {
//     return <svg width="30" height="30" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path d="M87.2583 45.1291C87.2583 68.3964 68.3964 87.2583 45.1291 87.2583C21.8619 87.2583 3 68.3964 3 45.1291C3 21.8619 21.8619 3 45.1291 3C68.3964 3 87.2583 21.8619 87.2583 45.1291Z" fill="#F74C54" />
//         <path d="M84.7583 45.1291C84.7583 67.0157 67.0157 84.7583 45.1291 84.7583V89.7583C69.7771 89.7583 89.7583 69.7771 89.7583 45.1291H84.7583ZM45.1291 84.7583C23.2426 84.7583 5.5 67.0157 5.5 45.1291H0.5C0.5 69.7771 20.4811 89.7583 45.1291 89.7583V84.7583ZM5.5 45.1291C5.5 23.2426 23.2426 5.5 45.1291 5.5V0.5C20.4811 0.5 0.5 20.4811 0.5 45.1291H5.5ZM45.1291 5.5C67.0157 5.5 84.7583 23.2426 84.7583 45.1291H89.7583C89.7583 20.4811 69.7771 0.5 45.1291 0.5V5.5Z" fill="#F74C54" />
//         <path d="M51.8928 53.1336L53.6605 51.3658L51.9821 49.6874L50.2189 51.2766L51.8928 53.1336ZM53.1338 51.8925L51.2768 50.2187L49.6876 51.9818L51.366 53.6603L53.1338 51.8925ZM66.1938 64.9525L67.9616 66.7203L69.7294 64.9525L67.9616 63.1848L66.1938 64.9525ZM64.9528 66.1936L63.185 67.9614L64.9528 69.7291L66.7206 67.9614L64.9528 66.1936ZM50.2189 51.2766C47.7059 53.5418 44.3854 54.9167 40.7408 54.9167V59.9167C45.6695 59.9167 50.171 58.0512 53.5666 54.9905L50.2189 51.2766ZM40.7408 54.9167C32.9181 54.9167 26.5647 48.5633 26.5647 40.7406H21.5647C21.5647 51.3247 30.1566 59.9167 40.7408 59.9167V54.9167ZM26.5647 40.7406C26.5647 32.9178 32.9181 26.5645 40.7408 26.5645V21.5645C30.1566 21.5645 21.5647 30.1564 21.5647 40.7406H26.5647ZM40.7408 26.5645C48.5636 26.5645 54.9169 32.9178 54.9169 40.7406H59.9169C59.9169 30.1564 51.325 21.5645 40.7408 21.5645V26.5645ZM54.9169 40.7406C54.9169 44.3851 53.542 47.7056 51.2768 50.2187L54.9908 53.5663C58.0514 50.1708 59.9169 45.6693 59.9169 40.7406H54.9169ZM51.366 53.6603L64.4261 66.7203L67.9616 63.1848L54.9016 50.1247L51.366 53.6603ZM64.4261 63.1848L63.185 64.4258L66.7206 67.9614L67.9616 66.7203L64.4261 63.1848ZM66.7206 64.4258L53.6605 51.3658L50.125 54.9013L63.185 67.9614L66.7206 64.4258ZM40.7408 28.3194C47.5946 28.3194 53.1616 33.8864 53.1616 40.7402H58.1616C58.1616 31.125 50.356 23.3194 40.7408 23.3194V28.3194ZM53.1616 40.7402C53.1616 47.594 47.5946 53.1609 40.7408 53.1609V58.1609C50.356 58.1609 58.1616 50.3554 58.1616 40.7402H53.1616ZM40.7408 53.1609C33.887 53.1609 28.3201 47.594 28.3201 40.7402H23.3201C23.3201 50.3554 31.1256 58.1609 40.7408 58.1609V53.1609ZM28.3201 40.7402C28.3201 33.8864 33.887 28.3194 40.7408 28.3194V23.3194C31.1256 23.3194 23.3201 31.125 23.3201 40.7402H28.3201Z" fill="white" />
//     </svg>;
// };

const CaretDownIcon = () => {
    return <svg width="30" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.86873 4.79195C1.56248 5.11014 1.56248 5.62312 1.86873 5.94131L7.06247 11.3374C7.30622 11.5907 7.69997 11.5907 7.94372 11.3374L13.1375 5.94131C13.4437 5.62312 13.4437 5.11014 13.1375 4.79195C12.8174 4.78546 12.7182 4.78546 12.0312 4.79195L7.46024 4.79195L2.96873 4.78546C2.30151 4.79195 2.20231 4.79195 1.86873 4.79195Z" fill="#F74750" />
    </svg>
}

// const SearchIndicatorsContainer = props => {
//     return (
//         <components.IndicatorsContainer {...props}>
//             <SearchIcon />
//         </components.IndicatorsContainer>
//     );
// };

const IndicatorsContainer = props => {
    return (
        <components.IndicatorsContainer {...props}>
            <CaretDownIcon />
        </components.IndicatorsContainer>
    );
};

export const getQueryStringValue = (
    key,
    queryString = window.location.search.slice(1)
) => {
    const values = qs.parse(queryString);
    return values[key];
};

const ListDevelopment = (props) => {
    const { t } = useTranslation();

    const [groups, setGroups] = useState(null);
    const [operationType, setOperationType] = useState(props.match.params.operation ? props.match.params.operation.toLowerCase() : "");
    const [propertyType, setPropertyType] = useState(props.match.params.type ? props.match.params.type.toLowerCase() : "");
    const [city, setCity] = useState(props.match.params.city ? props.match.params.city.toLowerCase() : "");
    const [bedrooms, setBedrooms] = useState(Number(getQueryStringValue('bedrooms')) || 0)
    const [bathrooms, setBathrooms] = useState(Number(getQueryStringValue('bathrooms')) || 0)
    const [parkingSpots, setParkingSpots] = useState(Number(getQueryStringValue('parkingSpots')) || 0)
    const [priceMin, setPriceMin] = useState(Number(getQueryStringValue('priceMin')) || "");
    const [priceMax, setPriceMax] = useState(Number(getQueryStringValue('priceMax')) || "");
    const [sizeMin, setSizeMin] = useState(Number(getQueryStringValue('sizeMin')) || "");
    const [sizeMax, setSizeMax] = useState(Number(getQueryStringValue('sizeMax')) || "");
    const [propertyAge, setPropertyAge] = useState("");

    const { loading, data, error } = useQuery(LIST_DEVELOPMENTS, {
        variables: {
            operationType: operationType,
            propertyType: propertyType,
            city: city,
        }
    })

    const eventTrack = (category, action, label) => {
        // console.log("GA event:", category, ":", action, ":", label);
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        });

        trackAmplitude(category, {
            action: action,
            label: label
        });
    }

    // const loadCityOptions = async (inputText, callback) => {
    //     const response = await fetch(`https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?query=${inputText}&apiKey=l-zc7x-OI-k5ZMh3lWXjXyT2jAzH_OS6lqQZwAEXj7Y`);
    //     const json = await response.json();

    //     callback(json.suggestions.map(i => ({ label: i.label, value: i.address })));
    // }

    useEffect(() => {
        if (data) {
            const groupsFiltered = data.groups.map(group => {
                return {
                    id: group.id,
                    name: group.name,
                    display_name: group.display_name,
                    delivery_date: group.delivery_date,
                    display_delivery_date: dateStringFormat(group.delivery_date),
                    badge_image_url: group.badge_image_url,
                    main_image: group.gallery_urls.filter(i => i.tag === 'main')[0],
                    photo_image: group.gallery_urls.filter(i => i.tag === 'photo'),
                    amenities_image: group.gallery_urls.filter(i => i.tag === 'amenity'),
                    is_published: group.is_published,
                    sold_out: group.sold_out,
                    landing_page_url: group.landing_page_url,
                    address: group.address,
                    displayAddress: concatAddress(group.address),
                    unitTypes: group.unitTypes.filter(unitType => !propertyType || unitType.type.toLowerCase() === propertyType).map(unitType => {
                        return {
                            id: unitType.id,
                            type: unitType.type,
                            name: unitType.name,
                            display_name: unitType.display_name,
                            main_image: unitType.gallery_urls.filter(i => i.tag === 'main')[0],
                            layout_image: unitType.gallery_urls.filter(i => i.tag === 'layout')[0],
                            units: unitType.units.filter(unit =>
                                unit.status.toLowerCase() === 'disponible'
                                && (!operationType || (operationType === 'compra' && (unit.operationType.toLowerCase() === 'venta' || unit.operationType.toLowerCase() === 'preventa')) || unit.operationType.toLowerCase() === operationType)
                                && (!bedrooms || unit.bedrooms >= bedrooms)
                                && (!bathrooms || unit.bathrooms >= bathrooms)
                                && (!parkingSpots || unit.parking_spots >= parkingSpots)
                                && (!priceMin || unit.price >= priceMin)
                                && (!priceMax || unit.price <= priceMax)
                                && (!sizeMin || unit.size >= sizeMin)
                                && (!sizeMax || unit.size <= sizeMax)
                                && (!propertyAge || propertyAge === unit.operationType.toLowerCase())
                            )
                        }
                    }),
                    units: [].concat(...group.unitTypes.filter(unitType => !propertyType || unitType.type.toLowerCase() === propertyType).map(unitType => {
                        return unitType.units.filter(unit => {
                            return unit.status.toLowerCase() === 'disponible'
                                && (!operationType || (operationType === 'compra' && (unit.operationType.toLowerCase() === 'venta' || unit.operationType.toLowerCase() === 'preventa')) || unit.operationType.toLowerCase() === operationType)
                                && (!bedrooms || unit.bedrooms >= bedrooms)
                                && (!bathrooms || unit.bathrooms >= bathrooms)
                                && (!parkingSpots || unit.parking_spots >= parkingSpots)
                                && (!priceMin || unit.price >= priceMin)
                                && (!priceMax || unit.price <= priceMax)
                                && (!sizeMin || unit.size >= sizeMin)
                                && (!sizeMax || unit.size <= sizeMax)
                                && (!propertyAge || propertyAge === unit.operationType.toLowerCase())
                        })
                    }))
                }
            });

            setGroups(groupsFiltered)
        }
    }, [loading, data, operationType, propertyType, city, bedrooms, bathrooms, parkingSpots, priceMin, priceMax, sizeMin, sizeMax, propertyAge]);

    return (
        <>
            <Helmet>
                <title>{t('DevelopmentList.title')}</title>
                <meta
                    name="description"
                    content="¿Buscas casas ó apartamentos en venta?. Aprovecha los precios de preventa y pregunta por las diferentes opciones de crédito hipotecario que tenemos para ti."
                />
                <link rel='canonical' href='https://www.monplaces.com/developments' />
            </Helmet>
            <div className='container-fluid'>
                <div className='development-list-container'>
                    <div className='mobile-hide'>
                        <div className='center' style={{ paddingBottom: '20px' }}>
                            <h3 style={{ color: '#F74750', textAlign: 'center', fontSize: '26px', padding: '10px 20px' }}>{t('DevelopmentList.selecciona')}</h3>
                            <svg width="30" height="30" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M45.1287 87.4448C21.8616 87.3411 3.084 68.3954 3.18766 45.1283C3.29132 21.8613 22.237 3.08364 45.5041 3.18731C68.7711 3.29097 87.5488 22.2367 87.4451 45.5037C87.3415 68.7708 68.3957 87.5484 45.1287 87.4448Z" fill="#F74C54" />
                                <path d="M45.1398 84.9448C23.2535 84.8473 5.59013 67.0258 5.68764 45.1395L0.687688 45.1172C0.577876 69.765 20.4698 89.8349 45.1176 89.9447L45.1398 84.9448ZM5.68764 45.1395C5.78515 23.2531 23.6066 5.58977 45.4929 5.68728L45.5152 0.68733C20.8675 0.577517 0.7975 20.4694 0.687688 45.1172L5.68764 45.1395ZM45.4929 5.68728C67.3793 5.78479 85.0427 23.6062 84.9451 45.4926L89.9451 45.5149C90.0549 20.8671 70.163 0.797142 45.5152 0.68733L45.4929 5.68728ZM84.9451 45.4926C84.8476 67.3789 67.0262 85.0423 45.1398 84.9448L45.1176 89.9447C69.7653 90.0545 89.8353 70.1626 89.9451 45.5149L84.9451 45.4926Z" fill="#F74C54" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.8412 35.3948C24.7951 36.472 24.7874 38.2161 25.8238 39.3026L43.4007 57.7279C44.2256 58.5926 45.5643 58.5986 46.3969 57.7412L64.1373 39.4733C65.1833 38.3961 65.1911 36.652 64.1547 35.5655C63.0665 35.5386 62.7292 35.5371 60.3935 35.5488L44.8522 35.4795L29.5813 35.3894C27.3127 35.4014 26.9754 35.3999 25.8412 35.3948Z" fill="white" />
                            </svg>
                        </div>
                        <div className='search-box'>
                            <div className='city'>
                                {/* <AsyncSelect
                                    placeholder='Ciudad, delegación ó colonia'
                                    loadOptions={loadCityOptions}
                                    onChange={(location) => {
                                        eventTrack("Development List Screen", "Location Filter", "Select")
                                        setCity(location.value.city ? location.value.city : location.value.state)
                                    }}
                                    styles={customStyles}
                                    components={{ IndicatorsContainer: SearchIndicatorsContainer }}
                                /> */}
                                <p>{t('Common.ubicacion')}</p>

                                {locationOptions.map(location => (
                                    <div key={location.value} style={{ display: 'inline-block' }}>
                                        <input
                                            type="radio"
                                            id={`radio${location.value}`}
                                            name="locationGroup"
                                            value={location.value}
                                            checked={city === location.value}
                                            onChange={(e) => {
                                                eventTrack("Development List Screen", "Location Filter", "Select")
                                                let { value } = e.target;
                                                setCity(value);
                                            }}
                                            onClick={(e) => {
                                                let { value } = e.target;
                                                if (value === city) {
                                                    setCity(null);
                                                }
                                            }}
                                        />
                                        <label for={`radio${location.value}`}>{location.label}</label>
                                    </div>

                                ))}
                            </div>
                            <hr />
                            <div className='propertyType'>
                                <p>{t('Common.tipoPropiedad')}</p>

                                {propertyTypeOptions.map(type => (
                                    <div key={type.value}>
                                        <input
                                            type="radio"
                                            id={`radio${type.value}`}
                                            name="propertyTypeGroup"
                                            value={type.value}
                                            checked={propertyType === type.value}
                                            onChange={(e) => {
                                                eventTrack("Development List Screen", "PropertyType Filter", "Select")
                                                let { value } = e.target;
                                                setPropertyType(value);
                                            }}
                                            onClick={(e) => {
                                                let { value } = e.target;
                                                if (value === propertyType) {
                                                    setPropertyType(null);
                                                }
                                            }}
                                        />
                                        <label for={`radio${type.value}`}>{type.label}</label>
                                    </div>

                                ))}
                            </div>
                            <hr />
                            <div className='operationType'>
                                <p>{t('Common.tipoOperacion')}</p>

                                {operationTypeOptions.map(type => (
                                    <div key={type.value}>
                                        <input
                                            type="radio"
                                            id={`radio${type.value}`}
                                            name="operationTypeGroup"
                                            value={type.value}
                                            checked={operationType === type.value}
                                            onChange={(e) => {
                                                eventTrack("Development List Screen", "OperationType Filter", "Select")
                                                let { value } = e.target;
                                                setOperationType(value);
                                            }}
                                            onClick={(e) => {
                                                let { value } = e.target;
                                                if (value === operationType) {
                                                    setOperationType(null);
                                                }
                                            }}
                                        />
                                        <label for={`radio${type.value}`}>{type.label}</label>
                                    </div>
                                ))}
                            </div>
                            <hr />
                            <div>
                                <p>{t('Common.antiguedad')}</p>

                                {propertyAgeOptions.map(type => (
                                    <div style={{ display: 'inline-block' }} key={type.value}>
                                        <input
                                            type="radio"
                                            id={`radio${type.value}`}
                                            name="propertyAgeGroup"
                                            value={type.value}
                                            checked={propertyAge === type.value}
                                            onChange={(e) => {
                                                eventTrack("Development List Screen", "PropertyAge Filter", "Select")
                                                let { value } = e.target;
                                                setPropertyAge(value);
                                            }}
                                            onClick={(e) => {
                                                let { value } = e.target;
                                                if (value === propertyAge) {
                                                    setPropertyAge(null);
                                                }
                                            }}
                                        />
                                        <label for={`radio${type.value}`}>{type.label}</label>
                                    </div>
                                ))}
                            </div>
                            <hr />
                            <div>
                                <p>{t('Common.precio')}</p>

                                <div style={{ display: 'inline-block', width: '100%' }}>
                                    <span style={{ float: 'left' }}>{t('Common.minimo')}</span>
                                    <span style={{ float: 'right' }}>{t('Common.maximo')}</span>
                                </div>
                                <Slider
                                    range
                                    min={0}
                                    max={10000000}
                                    value={[priceMin, priceMax]}
                                    onChange={(value) => {
                                        eventTrack("Development List Screen", "Price Filter", "Select")
                                        setPriceMin(value[0]);
                                        setPriceMax(value[1]);
                                    }}
                                />
                                <div style={{ display: 'inline-block', width: '100%' }}>
                                    <span style={{ float: 'left' }}>{currencyFormat(priceMin)}</span>
                                    <span style={{ float: 'right' }}>{currencyFormat(priceMax)}</span>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <span>{t('Common.recamaras')}</span>

                                <div style={{ float: 'right' }}>
                                    <button
                                        className='btn-circular'
                                        onClick={() => {
                                            eventTrack("Development List Screen", "Bedrooms Filter", "Select")
                                            setBedrooms(bedrooms > 0 ? bedrooms - 1 : 0)
                                        }}>-</button>
                                    <span style={{ padding: '0 10px' }}>{bedrooms}</span>
                                    <button
                                        className='btn-circular'
                                        onClick={() => {
                                            eventTrack("Development List Screen", "Bedrooms Filter", "Select")
                                            setBedrooms(bedrooms < 10 ? bedrooms + 1 : 10)
                                        }}>+</button>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <span>{t('Common.banos')}</span>

                                <div style={{ float: 'right' }}>
                                    <button
                                        className='btn-circular'
                                        onClick={() => {
                                            eventTrack("Development List Screen", "Bathrooms Filter", "Select")
                                            setBathrooms(bathrooms > 0 ? bathrooms - 1 : 0)
                                        }}>-</button>
                                    <span style={{ padding: '0 10px' }}>{bathrooms}</span>
                                    <button
                                        className='btn-circular'
                                        onClick={() => {
                                            eventTrack("Development List Screen", "Bathrooms Filter", "Select")
                                            setBathrooms(bathrooms < 10 ? bathrooms + 1 : 10)
                                        }}>+</button>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <span>{t('Common.estacionamientos')}</span>

                                <div style={{ float: 'right' }}>
                                    <button
                                        className='btn-circular'
                                        onClick={() => {
                                            eventTrack("Development List Screen", "ParkingSpots Filter", "Select")
                                            setParkingSpots(parkingSpots > 0 ? parkingSpots - 1 : 0)
                                        }}>-</button>
                                    <span style={{ padding: '0 10px' }}>{parkingSpots}</span>
                                    <button
                                        className='btn-circular'
                                        onClick={() => {
                                            eventTrack("Development List Screen", "ParkingSpots Filter", "Select")
                                            setParkingSpots(parkingSpots < 10 ? parkingSpots + 1 : 10)
                                        }}>+</button>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <p>{t('Common.superficie')}</p>

                                <div style={{ display: 'inline-block', width: '100%' }}>
                                    <span style={{ float: 'left' }}>{t('Common.minimo')}</span>
                                    <span style={{ float: 'right' }}>{t('Common.maximo')}</span>
                                </div>
                                <Slider
                                    range
                                    min={0}
                                    max={1000}
                                    value={[sizeMin, sizeMax]}
                                    onChange={(value) => {
                                        eventTrack("Development List Screen", "Size Filter", "Select")
                                        setSizeMin(value[0]);
                                        setSizeMax(value[1]);
                                    }}
                                />
                                <div style={{ display: 'inline-block', width: '100%' }}>
                                    <span style={{ float: 'left' }}>{sizeMin} m²</span>
                                    <span style={{ float: 'right' }}>{sizeMax} m²</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mobile-display'>
                        <div className='search-box'>
                            <div className='city'>
                                {/* <AsyncSelect
                                    placeholder='Ciudad, delegación ó colonia'
                                    loadOptions={loadCityOptions}
                                    onChange={(location) => {
                                        eventTrack("Development List Screen", "Location Filter", "Select")
                                        setCity(location.value.city ? location.value.city : location.value.state)
                                    }}
                                    styles={customStyles}
                                    components={{ IndicatorsContainer: SearchIndicatorsContainer }}
                                /> */}
                                <Select
                                    placeholder={t('Common.ubicacion')}
                                    options={locationOptions}
                                    onChange={(location) => {
                                        eventTrack("Development List Screen", "Location Filter", "Select")
                                        setCity(location.value)
                                    }}
                                    styles={customStyles}
                                    components={{ IndicatorsContainer }}
                                />
                            </div>
                            <div className='propertyType'>
                                <Select
                                    placeholder={t('Common.tipoPropiedad')}
                                    options={propertyTypeOptions}
                                    onChange={(type) => {
                                        eventTrack("Development List Screen", "PropertyType Filter", "Select")
                                        setPropertyType(type.value)
                                    }}
                                    styles={customStyles}
                                    components={{ IndicatorsContainer }}
                                />
                            </div>
                            <div className='operationType'>
                                <Select
                                    placeholder={t('Common.tipoOperacion')}
                                    options={operationTypeOptions}
                                    onChange={(type) => {
                                        eventTrack("Development List Screen", "OperationType Filter", "Select")
                                        setOperationType(type.value)
                                    }}
                                    styles={customStyles}
                                    components={{ IndicatorsContainer }}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>

                    {loading &&
                        <div className="container-fluid"><div className='row'>Loading...</div></div>
                    }

                    {error &&
                        <div className="container-fluid"><div className='row'>Error! {error.message}</div></div>
                    }

                    {!loading && !error &&
                        <div>
                            {groups &&
                                <>
                                    <div className='row mobile-display'>
                                        <h1 style={{ margin: '0px 20px', fontWeight: '600', fontSize: '1.2em' }}>
                                            {t('DevelopmentList.propiedades', { count: [].concat(...groups.map(d => d.units)).length })}
                                        </h1>
                                    </div>

                                    <div className='row mobile-hide'>
                                        <p style={{ margin: '20px 20px 0', fontWeight: '600' }}>
                                            {t('DevelopmentList.propiedades', { count: [].concat(...groups.map(d => d.units)).length })}
                                        </p>
                                    </div>
                                </>
                            }

                            {groups &&
                                groups.filter(group => group.units.length > 0).map(group => (
                                    group.is_published ?
                                        <div className='row' key={group.id}>
                                            <div className='development-summary'>
                                                <ImageGallerySimple
                                                    items={
                                                        [
                                                            group.main_image,
                                                            ...group.photo_image,
                                                            ...group.amenities_image
                                                        ]
                                                    }
                                                    handleClick={() => props.history.push(`/developments/${group.address.location}/${group.name}`)}
                                                />
                                                <Link className='info mobile-hide' to={`/developments/${group.address.location}/${group.name}`}>
                                                    {[...new Set(group.unitTypes.map(unitType => unitType.type))].map(type => (
                                                        <label className="badge" key={type}>{type}</label>
                                                    ))}
                                                    {[...new Set(group.units.map(unit => unit.operationType))].map(operationType => (
                                                        <label className="badge" key={operationType}>{operationType}</label>
                                                    ))}
                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 2fr', gap: '10px', alignItems: 'center' }}>
                                                        {
                                                            group.badge_image_url &&
                                                            <>
                                                                <div style={{ gridColumn: '1/3' }}>
                                                                    <p className="title">{group.display_name}</p>
                                                                    <p className="subtitle">
                                                                        {t('Common.precioInicial', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency) })}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <img style={{ width: '85px' }} src={group.badge_image_url} alt="badge" />
                                                                </div>
                                                            </>
                                                        }
                                                        {
                                                            !group.badge_image_url &&
                                                            <div style={{ gridColumn: '1/4' }}>
                                                                <p className="title">{group.display_name}</p>
                                                                <p className="subtitle">
                                                                    {t('Common.precioInicial', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency) })}
                                                                </p>
                                                            </div>
                                                        }

                                                        <div>
                                                            <p className="prefix-icon icon-land-size">
                                                                {t('Common.tamano', { minSize: minValue(group.units, 'size', true), maxSize: maxValue(group.units, 'size', true) })}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="prefix-icon icon-bathroom">
                                                                {t('Common.bano', { count: minValue(group.units, 'bathrooms') })}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="prefix-icon icon-calendar">{t('Common.entrega')}: {group.display_delivery_date}</p>
                                                        </div>
                                                        <div>
                                                            <p className="prefix-icon icon-bedroom">
                                                                {t('Common.recamara', { count: minValue(group.units, 'bedrooms') })}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="prefix-icon icon-car nowrap">
                                                                {t('Common.estacionamiento', { count: minValue(group.units, 'parking_spots') })}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <div className="prefix-icon icon-location">
                                                                <div>
                                                                    <p style={{ margin: '0' }}>{group.displayAddress}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className='development-unit-types mobile-hide'>
                                                    <p>{t('DevelopmentList.otrasUnidades')}</p>
                                                    <div className='cards'>
                                                        {group.unitTypes.filter(unitType => !propertyType || unitType.type.toLowerCase() === propertyType).map(unitType => (
                                                            unitType.units.length > 0 &&
                                                            <Link className='card-item' key={unitType.id} to={`/developments/${group.address.location}/${group.name}/${unitType.name}`}>
                                                                <div className='image'>
                                                                    <p className='title'>{t('Common.tipo')}: {unitType.display_name}</p>
                                                                    <img src={unitType.layout_image.url} alt={unitType.display_name} style={{ height: '160px' }}></img>
                                                                    {/* {unitType.units.length === 1 &&
                                                                        <label className="badge">{unitType.units.length} unidad disponible</label>
                                                                    }
                                                                    {unitType.units.length !== 1 &&
                                                                        <label className="badge">{unitType.units.length} unidades disponibles</label>
                                                                    } */}
                                                                </div>
                                                                <div className='info outer'>
                                                                    <div className='middle'>
                                                                        <p className="subtitle">{currencyFormat(minValue(unitType.units, 'price'), unitType.units[0].currency)}</p>
                                                                        <p className="prefix-icon icon-land-size">{minValue(unitType.units, 'size')} m2</p>
                                                                        <p className="prefix-icon icon-bedroom">
                                                                            {t('Common.recamara', { count: minValue(unitType.units, 'bedrooms') })}
                                                                        </p>
                                                                        <p className="prefix-icon icon-bathroom">
                                                                            {t('Common.bano', { count: minValue(unitType.units, 'bathrooms') })}
                                                                        </p>
                                                                        <p className="prefix-icon icon-car nowrap">
                                                                            {t('Common.estacionamiento', { count: minValue(unitType.units, 'parking_spots') })}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className='info mobile-display'>
                                                    <div>
                                                        {[...new Set(group.unitTypes.map(unitTypes => unitTypes.type))].map(type => (
                                                            <label className="badge" key={type}>{type}</label>
                                                        ))}
                                                        {[...new Set(group.units.map(unit => unit.operationType))].map(operationType => (
                                                            <label className="badge" key={operationType}>{operationType}</label>
                                                        ))}
                                                        <label style={{ marginTop: '10px' }}>{t('Common.entrega')}: {group.display_delivery_date}</label>
                                                    </div>

                                                    <p className="title" style={{ marginBottom: '5px' }}>{group.display_name}</p>
                                                    <p className="subtitle">
                                                        {t('Common.precioInicial', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency) })}
                                                    </p>
                                                    {
                                                        group.badge_image_url && <img style={{ width: '80px', marginBottom: '10px' }} src={group.badge_image_url} alt="badge" />
                                                    }
                                                    <p className='prefix-icon icon-land-size'>De {minValue(group.units, 'size', true)}m2 a {maxValue(group.units, 'size', true)}m2</p>
                                                    <p className='prefix-icon icon-location'>{group.displayAddress}</p>
                                                    <div className='center' style={{ margin: '15px auto' }}>
                                                        <Link className='btn btn-action' style={{ margin: '15px 50px 10px', padding: '10px' }} to={`/developments/${group.address.location}/${group.name}`}>{t('DevelopmentList.explorar')}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        group.sold_out ?
                                            <div className='row' key={group.id}>
                                                <div className='development-summary sold-out'>
                                                    <ImageGallerySimple
                                                        items={[group.main_image]}
                                                        handleClick={() => { }}
                                                    />
                                                    <div className='info mobile-hide'>
                                                        {[...new Set(group.unitTypes.map(unitTypes => unitTypes.type))].map(type => (
                                                            <label className="badge" key={type}>{type}</label>
                                                        ))}
                                                        {[...new Set(group.units.map(unit => unit.operationType))].map(operationType => (
                                                            <label className="badge" key={operationType}>{operationType}</label>
                                                        ))}

                                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 2fr', gap: '10px', alignItems: 'center' }}>
                                                            <div style={{ gridColumn: '1/3' }}>
                                                                <p className="title">{group.display_name}</p>
                                                                <p className="subtitle">
                                                                    {t('Common.precioInicial', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency) })}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                {
                                                                    group.badge_image_url && <img style={{ width: '85px' }} src={group.badge_image_url} alt="badge" />
                                                                }
                                                            </div>

                                                            <div>
                                                                <p className="prefix-icon icon-land-size">
                                                                    {t('Common.tamano', { minSize: minValue(group.units, 'size', true), maxSize: maxValue(group.units, 'size', true) })}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="prefix-icon icon-bathroom">
                                                                    {t('Common.bano', { count: minValue(group.units, 'bathrooms') })}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="prefix-icon icon-calendar">{t('Common.entrega')}: {group.display_delivery_date}</p>
                                                            </div>
                                                            <div>
                                                                <p className="prefix-icon icon-bedroom">
                                                                    {t('Common.recamara', { count: minValue(group.units, 'bedrooms') })}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="prefix-icon icon-car nowrap">
                                                                    {t('Common.estacionamiento', { count: minValue(group.units, 'parking_spots') })}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <div className="prefix-icon icon-location">
                                                                    <div>
                                                                        <p style={{ margin: '0' }}>{group.displayAddress}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='info mobile-display'>
                                                        <div>
                                                            {[...new Set(group.unitTypes.map(unitTypes => unitTypes.type))].map(type => (
                                                                <label className="badge" key={type}>{type}</label>
                                                            ))}
                                                            {[...new Set(group.units.map(unit => unit.operationType))].map(operationType => (
                                                                <label className="badge" key={operationType}>{operationType}</label>
                                                            ))}
                                                            <label>{t('Common.entrega')}: {group.display_delivery_date}</label>
                                                        </div>

                                                        <p className="title" style={{ marginBottom: '5px' }}>{group.display_name}</p>
                                                        <p className="subtitle">
                                                            {t('Common.precioInicial', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency) })}
                                                        </p>
                                                        {
                                                            group.badge_image_url && <img style={{ width: '80px', marginBottom: '10px' }} src={group.badge_image_url} alt="badge" />
                                                        }
                                                        <p className='prefix-icon icon-land-size'>
                                                            {t('Common.tamano', { minSize: minValue(group.units, 'size', true), sizeMax: maxValue(group.units, 'size', true) })}
                                                        </p>
                                                        <p className='prefix-icon icon-location'>{group.displayAddress}</p>
                                                    </div>
                                                    <div className='bg-text'>
                                                        <div>
                                                            <p className="title">
                                                                {t('DevelopmentList.proyectoVendido')}
                                                            </p>
                                                            <Link className="btn btn-action uppercase" style={{ margin: '20px auto' }} to={`/developments`}>
                                                                {t('Home.explorarMas')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='row' key={group.id}>
                                                <div className='development-summary coming-soon'>
                                                    <ImageGallerySimple
                                                        items={[group.main_image]}
                                                        handleClick={() => { }}
                                                    />
                                                    <div className='info mobile-hide'>
                                                        {[...new Set(group.unitTypes.map(unitTypes => unitTypes.type))].map(type => (
                                                            <label className="badge" key={type}>{type}</label>
                                                        ))}
                                                        {[...new Set(group.units.map(unit => unit.operationType))].map(operationType => (
                                                            <label className="badge" key={operationType}>{operationType}</label>
                                                        ))}

                                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 2fr', gap: '10px', alignItems: 'center' }}>
                                                            <div style={{ gridColumn: '1/3' }}>
                                                                <p className="title">{group.display_name}</p>
                                                                <p className="subtitle">
                                                                    {t('Common.precioInicial', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency) })}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                {
                                                                    group.badge_image_url && <img style={{ width: '85px' }} src={group.badge_image_url} alt="badge" />
                                                                }
                                                            </div>

                                                            <div>
                                                                <p className="prefix-icon icon-land-size">
                                                                    {t('Common.tamano', { minSize: minValue(group.units, 'size', true), maxSize: maxValue(group.units, 'size', true) })}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="prefix-icon icon-bathroom">
                                                                    {t('Common.bano', { count: minValue(group.units, 'bathrooms') })}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="prefix-icon icon-calendar">{t('Common.entrega')}: {group.display_delivery_date}</p>
                                                            </div>
                                                            <div>
                                                                <p className="prefix-icon icon-bedroom">
                                                                    {t('Common.recamara', { count: minValue(group.units, 'bedrooms') })}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="prefix-icon icon-car nowrap">
                                                                    {t('Common.estacionamiento', { count: minValue(group.units, 'parking_spots') })}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <div className="prefix-icon icon-location">
                                                                    <div>
                                                                        <p style={{ margin: '0' }}>{group.displayAddress}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='info mobile-display'>
                                                        <div>
                                                            {[...new Set(group.unitTypes.map(unitTypes => unitTypes.type))].map(type => (
                                                                <label className="badge" key={type}>{type}</label>
                                                            ))}
                                                            {[...new Set(group.units.map(unit => unit.operationType))].map(operationType => (
                                                                <label className="badge" key={operationType}>{operationType}</label>
                                                            ))}
                                                            <label>{t('Common.entrega')}: {group.display_delivery_date}</label>
                                                        </div>

                                                        <p className="title" style={{ marginBottom: '5px' }}>{group.display_name}</p>
                                                        <p className="subtitle">
                                                            {t('Common.precioInicial', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency) })}
                                                        </p>
                                                        {
                                                            group.badge_image_url && <img style={{ width: '80px', marginBottom: '10px' }} src={group.badge_image_url} alt="badge" />
                                                        }
                                                        <p className='prefix-icon icon-land-size'>
                                                            {t('Common.tamano', { minSize: minValue(group.units, 'size', true), sizeMax: maxValue(group.units, 'size', true) })}
                                                        </p>
                                                        <p className='prefix-icon icon-location'>{group.displayAddress}</p>
                                                    </div>
                                                    <div className='bg-text'>
                                                        <div>
                                                            <p>
                                                                {t('DevelopmentList.desdeEnUbicacion', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency), city: group.address.city, state: group.address.state })}
                                                            </p>
                                                            <p className="title">{t('DevelopmentList.proximamente')}</p>
                                                            <a className="btn btn-action uppercase" style={{ marginBottom: '20px' }}
                                                                href={group.landing_page_url ? group.landing_page_url : `https://wa.me/523318904974?text=Hola Monplaces, quiero recibir información de ${group.display_name}...`}
                                                                target='_blank' rel='noopener noreferrer'>{t('DevelopmentList.registrate')}</a>
                                                            <p>{t('DevelopmentList.listaCero')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                ))
                            }
                            <div>
                                <div className='row'>
                                    <div className='social-links'>
                                        <a href="https://www.facebook.com/monplaces" target='_blank' rel='noopener noreferrer'><img src="/images/facebook.svg" alt="Facebook"></img></a>
                                        <a href="https://twitter.com/monplaces" target='_blank' rel='noopener noreferrer'><img src="/images/twitter.svg" alt="Twitter"></img></a>
                                        <a href="https://www.instagram.com/monplaces_latam/" target='_blank' rel='noopener noreferrer'><img src="/images/instagram.svg" alt="Instagram"></img></a>
                                    </div>
                                    <div className='center'>
                                        <a href="mailto:customer@monplaces.com">customer@monplaces.com</a>&nbsp;--&nbsp;
                                        <Link to="/privacy">{t('Navigation.privacidad')}</Link>&nbsp;--&nbsp;
                                        <Link to="/terms">{t('Navigation.terminos')}</Link>&nbsp;--&nbsp;
                                        {/* <a href="#">Cookies</a> */}
                                    </div>
                                    <div className='center'>
                                        <p>Monplaces © {/\d{4}/.exec(Date())[0]}</p>
                                        <p>{t('Copy.copyright')}</p>
                                    </div>
                                    <div className='footer-logo'>
                                        <img src="/isotipo.webp" alt="Monplaces"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default ListDevelopment;