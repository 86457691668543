import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery, gql } from "@apollo/client";
import auth from '../../Auth';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import ReactGA from 'react-ga4';
import { track as trackAmplitude } from '@amplitude/analytics-browser';

import { useTranslation } from 'react-i18next';

import { currencyFormat, concatAddress, minValue, maxValue } from '../../utils.js';
import ImageGallerySimple from '../../components/ImageGallerySimple/ImageGallerySimple';
import News from '../../components/News/News';

import '../../App.css';
import './Home.css';

const { REACT_APP_WEBSITE_NAME } = process.env;

const LIST_DEVELOPMENTS = gql`
  query TopDevelopments($offset: Int, $limit: Int) {
    groups(offset: $offset, limit: $limit) {
      id
      name
      display_name
      badge_image_url
      gallery_urls {
        url
        thumbnailUrl
        type
        tag
      }
      is_published
      sold_out
      landing_page_url
      address {
        line_1
        line_2
        city
        state
        country
        zip_code
        location
      }
      unitTypes {
        type
        gallery_urls {
            url
            type
            tag
        }
        units {
            price
            size
            operationType
            currency
            status
        }
      }
    }
  }
`

const locationOptions = [
    { value: 'guatemala', label: 'Cdad. de Guatemala' },
    { value: 'muxbal', label: 'San Jorge Muxbal' },
];

const propertyTypeOptions = [
    { value: 'casa', label: 'Casa' },
    { value: 'departamento', label: 'Apartamento' },
    { value: 'terreno', label: 'Terreno' },
];

const operationTypeOptions = [
    { value: 'compra', label: 'Compra' },
    { value: 'renta', label: 'Renta' },
];

const customStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: '#465C66',
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? 'rgba(41, 171, 184, 0.18)'
                : isFocused
                    ? 'rgba(41, 171, 184, 0.18)'
                    : undefined,
        padding: '5px',
    }),
    control: (styles) => ({
        ...styles,
        border: 'none',
        minHeight: '30px',
        marginBottom: '5px',
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#000'
    })
}

const customStylesMobile = {
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: '#465C66',
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? 'rgba(41, 171, 184, 0.18)'
                : isFocused
                    ? 'rgba(41, 171, 184, 0.18)'
                    : undefined,
        padding: '5px',
    }),
    control: (styles) => ({
        ...styles,
        padding: '5px',
        border: 'none',
        marginBottom: '5px',
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#000'
    })
}

// const SearchIcon = () => {
//     return <svg width="30" height="30" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path d="M87.2583 45.1291C87.2583 68.3964 68.3964 87.2583 45.1291 87.2583C21.8619 87.2583 3 68.3964 3 45.1291C3 21.8619 21.8619 3 45.1291 3C68.3964 3 87.2583 21.8619 87.2583 45.1291Z" fill="#F74C54" />
//         <path d="M84.7583 45.1291C84.7583 67.0157 67.0157 84.7583 45.1291 84.7583V89.7583C69.7771 89.7583 89.7583 69.7771 89.7583 45.1291H84.7583ZM45.1291 84.7583C23.2426 84.7583 5.5 67.0157 5.5 45.1291H0.5C0.5 69.7771 20.4811 89.7583 45.1291 89.7583V84.7583ZM5.5 45.1291C5.5 23.2426 23.2426 5.5 45.1291 5.5V0.5C20.4811 0.5 0.5 20.4811 0.5 45.1291H5.5ZM45.1291 5.5C67.0157 5.5 84.7583 23.2426 84.7583 45.1291H89.7583C89.7583 20.4811 69.7771 0.5 45.1291 0.5V5.5Z" fill="#F74C54" />
//         <path d="M51.8928 53.1336L53.6605 51.3658L51.9821 49.6874L50.2189 51.2766L51.8928 53.1336ZM53.1338 51.8925L51.2768 50.2187L49.6876 51.9818L51.366 53.6603L53.1338 51.8925ZM66.1938 64.9525L67.9616 66.7203L69.7294 64.9525L67.9616 63.1848L66.1938 64.9525ZM64.9528 66.1936L63.185 67.9614L64.9528 69.7291L66.7206 67.9614L64.9528 66.1936ZM50.2189 51.2766C47.7059 53.5418 44.3854 54.9167 40.7408 54.9167V59.9167C45.6695 59.9167 50.171 58.0512 53.5666 54.9905L50.2189 51.2766ZM40.7408 54.9167C32.9181 54.9167 26.5647 48.5633 26.5647 40.7406H21.5647C21.5647 51.3247 30.1566 59.9167 40.7408 59.9167V54.9167ZM26.5647 40.7406C26.5647 32.9178 32.9181 26.5645 40.7408 26.5645V21.5645C30.1566 21.5645 21.5647 30.1564 21.5647 40.7406H26.5647ZM40.7408 26.5645C48.5636 26.5645 54.9169 32.9178 54.9169 40.7406H59.9169C59.9169 30.1564 51.325 21.5645 40.7408 21.5645V26.5645ZM54.9169 40.7406C54.9169 44.3851 53.542 47.7056 51.2768 50.2187L54.9908 53.5663C58.0514 50.1708 59.9169 45.6693 59.9169 40.7406H54.9169ZM51.366 53.6603L64.4261 66.7203L67.9616 63.1848L54.9016 50.1247L51.366 53.6603ZM64.4261 63.1848L63.185 64.4258L66.7206 67.9614L67.9616 66.7203L64.4261 63.1848ZM66.7206 64.4258L53.6605 51.3658L50.125 54.9013L63.185 67.9614L66.7206 64.4258ZM40.7408 28.3194C47.5946 28.3194 53.1616 33.8864 53.1616 40.7402H58.1616C58.1616 31.125 50.356 23.3194 40.7408 23.3194V28.3194ZM53.1616 40.7402C53.1616 47.594 47.5946 53.1609 40.7408 53.1609V58.1609C50.356 58.1609 58.1616 50.3554 58.1616 40.7402H53.1616ZM40.7408 53.1609C33.887 53.1609 28.3201 47.594 28.3201 40.7402H23.3201C23.3201 50.3554 31.1256 58.1609 40.7408 58.1609V53.1609ZM28.3201 40.7402C28.3201 33.8864 33.887 28.3194 40.7408 28.3194V23.3194C31.1256 23.3194 23.3201 31.125 23.3201 40.7402H28.3201Z" fill="white" />
//     </svg>;
// };

const CaretDownIcon = () => {
    return <svg width="30" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.86873 4.79195C1.56248 5.11014 1.56248 5.62312 1.86873 5.94131L7.06247 11.3374C7.30622 11.5907 7.69997 11.5907 7.94372 11.3374L13.1375 5.94131C13.4437 5.62312 13.4437 5.11014 13.1375 4.79195C12.8174 4.78546 12.7182 4.78546 12.0312 4.79195L7.46024 4.79195L2.96873 4.78546C2.30151 4.79195 2.20231 4.79195 1.86873 4.79195Z" fill="#F74750" />
    </svg>
}

// const SearchIndicatorsContainer = props => {
//     return (
//         <components.IndicatorsContainer {...props}>
//             <SearchIcon />
//         </components.IndicatorsContainer>
//     );
// };

const IndicatorsContainer = props => {
    return (
        <components.IndicatorsContainer {...props}>
            <CaretDownIcon />
        </components.IndicatorsContainer>
    );
};

const Home = (props) => {
    const { t } = useTranslation();

    const [width, setWidth] = useState(window.innerWidth);
    const [operationType, setOperationType] = useState('compra');
    const [propertyType, setPropertyType] = useState(null);
    const [propertyBedrooms, setPropertyBedrooms] = useState(null);
    const [propertyPrice, setPropertyPrice] = useState(null);
    const [location, setLocation] = useState(null);
    const { loading, error, data } = useQuery(LIST_DEVELOPMENTS);
    const [groups, setGroups] = useState(null);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const eventTrack = (category, action, label) => {
        // console.log("GA event:", category, ":", action, ":", label);
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        });

        trackAmplitude(category, {
            action: action,
            label: label
        });
    }

    // const loadCityOptions = async (inputText, callback) => {
    //     const response = await fetch(`https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?query=${inputText}&apiKey=l-zc7x-OI-k5ZMh3lWXjXyT2jAzH_OS6lqQZwAEXj7Y`);
    //     const json = await response.json();

    //     callback(json.suggestions.map(i => ({ label: i.label, value: i.address })));
    // }

    const searchDevelopments = () => {
        if (operationType) {
            eventTrack("Home Screen", "Search Button", "Button")

            var url = `/${operationType}`;
            if (propertyType) {
                if (location) {
                    url = `${url}/${propertyType}/${location}`;
                }
                else {
                    url = `${url}/${propertyType}`;
                }
            }

            var queryString = '';
            if (propertyBedrooms) {
                queryString = `bedrooms=${propertyBedrooms}`;
            }
            if (propertyPrice) {
                switch (propertyPrice) {
                    case '1':
                        queryString += `&priceMin=${0}&priceMax=${2500000}`;
                        break;
                    case '2':
                        queryString += `&priceMin=${2500000}&priceMax=${4000000}`;
                        break;
                    case '3':
                        queryString += `&priceMin=${4000000}&priceMax=${6000000}`;
                        break;
                    case '4':
                        queryString += `&priceMin=${6000000}`;
                        break;
                    default:
                        break;
                }
            }

            if (queryString !== '') {
                props.history.push(`${url}?${queryString}`);
            }
            else {
                props.history.push(url);
            }
        }
    }

    useEffect(() => {
        if (data) {
            const groupsFiltered = data.groups
                .filter(group => group.badge_image_url)
                .map(group => {
                    return {
                        id: group.id,
                        name: group.name,
                        display_name: group.display_name,
                        badge_image_url: group.badge_image_url,
                        main_image: group.gallery_urls.filter(i => i.tag === 'main')[0],
                        photo_image: group.gallery_urls.filter(i => i.tag === 'photo'),
                        amenities_image: group.gallery_urls.filter(i => i.tag === 'amenity'),
                        is_published: group.is_published,
                        sold_out: group.sold_out,
                        landing_page_url: group.landing_page_url,
                        address: group.address,
                        displayAddress: concatAddress(group.address),
                        unitTypes: group.unitTypes.map(unitType => {
                            return {
                                id: unitType.id,
                                type: unitType.type,
                                name: unitType.name,
                                display_name: unitType.display_name,
                                main_image: unitType.gallery_urls.filter(i => i.tag === 'main')[0],
                                units: unitType.units.filter(unit => unit.status.toLowerCase() === 'disponible')
                            }
                        }),
                        units: [].concat(...group.unitTypes.map(unitType => {
                            return unitType.units.filter(unit => unit.status.toLowerCase() === 'disponible')
                        }))
                    }
                });

            setGroups(groupsFiltered)
        }

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [loading, data]);

    return (
        <>
            <Helmet>
                <title>{REACT_APP_WEBSITE_NAME}</title>
                <meta
                    name="description"
                    content="Somos la plataforma de bienes raíces en donde dueños, inversionistas, inquilinos, instituciones financieras, formas de pagos, créditos hipotecarios y contratos digitales, interactúan para realizar compra, venta y renta de casas y apartamentos en un solo lugar."
                />
                <link rel='canonical' href='https://www.monplaces.com/' />
            </Helmet>
            <div className="container-fluid">
                <div className='center mobile-display' style={{ paddingTop: '15px' }}>
                    <a
                        style={{ color: '#f74750', textDecoration: 'underline', fontSize: '18px', fontWeight: 'bold', padding: '10px 0px' }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://api.whatsapp.com/send?phone=523318904974&text=%C2%A1Hola!%20Me%20interesa%20conocer%20las%20opciones%20de%20crédito%20hipotecario%20por%20medio%20de%20Monplaces...."
                        onClick={eventTrack.bind(this, "Navigation Bar", "Mortage Link", "Link")}>
                        {t('Home.solicitaCreditoHipotecario')}
                    </a>
                </div>
                <div className='center'>
                    <h1 className="title">
                        {t('Home.simplificamosOperaciones')}
                        {/* Simplificamos la <strong>compra</strong>, <strong>venta</strong> y <strong>renta</strong> de <strong>propiedades</strong>, <span className='highlight'><strong>¡en un sólo lugar!</strong></span> */}
                    </h1>
                </div>
                <div className="home-banner mobile-hide">
                    <video className='home-banner-video' autoPlay loop muted>
                        <source src="https://monplaces.blob.core.windows.net/assets/monplaces.mp4" type='video/mp4' />
                    </video>
                    <div className="home-banner-container">
                        <div className="home-banner-content">
                            <span className="title"><strong>{t('Home.encuentraTuHogar')}</strong></span>
                            <span className="subtitle">
                                {t('Home.nosotrosTeAyudamos')}
                            </span>

                            <div className='mobile-hide'>
                                <div className='search-box'>
                                    <div className='operationType'>
                                        <input
                                            type="radio"
                                            id="radioBuy"
                                            name="operationTypeGroup"
                                            value="compra"
                                            checked={operationType === 'compra'}
                                            onChange={() => setOperationType('compra')} />
                                        <label for="radioBuy">{t('OperationType.compra')}</label>

                                        <input
                                            type="radio"
                                            id="radioRent"
                                            name="operationTypeGroup"
                                            value="renta"
                                            checked={operationType === 'renta'}
                                            onChange={() => setOperationType('renta')} />
                                        <label for="radioRent">{t('OperationType.renta')}</label>
                                    </div>
                                    <div className='group-box'>
                                        <div className='city' style={{ borderBottom: '1px solid #BDE1E1' }}>
                                            {/* <AsyncSelect
                                                placeholder='Ubicación (Ciudad, delegación, colonia)'
                                                loadOptions={loadCityOptions}
                                                onChange={(location) => {
                                                    setCity(location.value.city ? location.value.city : location.value.state)
                                                }}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: () => null }}
                                            /> */}
                                            <Select
                                                placeholder={t('Common.ubicacion')}
                                                options={locationOptions}
                                                onChange={(type) => setLocation(type.value)}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: IndicatorsContainer }}
                                            />
                                        </div>
                                        <div className='type'>
                                            <Select
                                                placeholder={t('Home.seleccionaTipoPropiedad')}
                                                options={propertyTypeOptions}
                                                onChange={(type) => setPropertyType(type.value)}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: IndicatorsContainer }}
                                            />
                                        </div>
                                        <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
                                            <Select
                                                placeholder={t('Common.precio')}
                                                options={[
                                                    { value: '1', label: t('Price.0-2.5') },
                                                    { value: '2', label: t('Price.2.5-4') },
                                                    { value: '3', label: t('Price.4-6') },
                                                    { value: '4', label: t('Price.6+') },
                                                ]}
                                                onChange={(price) => setPropertyPrice(price.value)}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: IndicatorsContainer }}
                                            />
                                            <Select
                                                placeholder={t('Common.recamaras')}
                                                options={[
                                                    { value: '1', label: '1' },
                                                    { value: '2', label: '2' },
                                                    { value: '3', label: '3' },
                                                    { value: '4', label: '4' },
                                                    { value: '5', label: '5+' },
                                                ]}
                                                onChange={(type) => setPropertyBedrooms(type.value)}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: IndicatorsContainer }}
                                            />
                                        </div>
                                    </div>
                                    <div className='center'>
                                        <button className="btn btn-login uppercase"
                                            style={{ fontSize: '15px', padding: '11px 50px' }}
                                            disabled={!operationType || !propertyType || !location}
                                            onClick={searchDevelopments}>
                                            {t('Buttons.buscar')}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='mobile-display'>
                                <div className='search-box'>
                                    <div className='group-box'>
                                        <div className='city'>
                                            {/* <AsyncSelect
                                                placeholder='Ciudad, delegación ó colonia'
                                                loadOptions={loadCityOptions}
                                                onChange={(location) => {
                                                    setCity(location.value.city ? location.value.city : location.value.state)
                                                }}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: SearchIndicatorsContainer }}
                                            /> */}
                                            <Select
                                                placeholder={t('Common.ubicacion')}
                                                options={locationOptions}
                                                onChange={(type) => setLocation(type.value)}
                                                styles={customStylesMobile}
                                                components={{ IndicatorsContainer: IndicatorsContainer }}
                                            />
                                        </div>
                                        <div className='type' style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
                                            <Select
                                                placeholder={t('Common.tipo')}
                                                options={propertyTypeOptions}
                                                onChange={(type) => setPropertyType(type.value)}
                                                styles={customStylesMobile}
                                                components={{ IndicatorsContainer }}
                                            />
                                            <Select
                                                placeholder={t('Common.recamaras')}
                                                options={[
                                                    { value: '1', label: '1' },
                                                    { value: '2', label: '2' },
                                                    { value: '3', label: '3' },
                                                    { value: '4', label: '4' },
                                                    { value: '5', label: '5+' },
                                                ]}
                                                onChange={(type) => setPropertyBedrooms(type.value)}
                                                styles={customStylesMobile}
                                                components={{ IndicatorsContainer }}
                                            />
                                        </div>
                                        <div className='operationType'>
                                            <Select
                                                placeholder={t('Common.tipoOperacion')}
                                                options={operationTypeOptions}
                                                onChange={(type) => setOperationType(type.value)}
                                                styles={customStylesMobile}
                                                components={{ IndicatorsContainer }}
                                            />
                                        </div>
                                    </div>
                                    <div className='center'>
                                        <button className="btn btn-login uppercase"
                                            style={{ fontSize: '15px', padding: '11px 50px' }}
                                            disabled={!operationType || !propertyType || !location}
                                            onClick={searchDevelopments}>
                                            {t('Buttons.buscar')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-banner mobile-display">
                    <div className="home-banner-container">
                        <div className="home-banner-content">
                            <span className="title"><strong> {t('Home.encuentraTuHogar')}</strong></span>
                            <span className="subtitle"> {t('Home.nosotrosTeAyudamos')}</span>

                            <div className='mobile-hide'>
                                <div className='search-box'>
                                    <div className='operationType'>
                                        <input
                                            type="radio"
                                            id="radioBuy"
                                            name="operationTypeGroup"
                                            value="compra"
                                            checked={operationType === 'compra'}
                                            onChange={() => setOperationType('compra')} />
                                        <label for="radioBuy">{t('OperationType.compra')}</label>

                                        <input
                                            type="radio"
                                            id="radioRent"
                                            name="operationTypeGroup"
                                            value="renta"
                                            checked={operationType === 'renta'}
                                            onChange={() => setOperationType('renta')} />
                                        <label for="radioRent">{t('OperationType.renta')}</label>
                                    </div>
                                    <div className='group-box'>
                                        <div className='city' style={{ borderBottom: '1px solid #BDE1E1' }}>
                                            {/* <AsyncSelect
                                                placeholder='Ubicación (Ciudad, delegación, colonia)'
                                                loadOptions={loadCityOptions}
                                                onChange={(location) => {
                                                    setCity(location.value.city ? location.value.city : location.value.state)
                                                }}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: () => null }}
                                            /> */}
                                            <Select
                                                placeholder={t('Common.ubicacion')}
                                                options={locationOptions}
                                                onChange={(type) => setLocation(type.value)}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: IndicatorsContainer }}
                                            />
                                        </div>
                                        <div className='type'>
                                            <Select
                                                placeholder={t('Home.seleccionaTipoPropiedad')}
                                                options={propertyTypeOptions}
                                                onChange={(type) => setPropertyType(type.value)}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: IndicatorsContainer }}
                                            />
                                        </div>
                                        <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
                                            <Select
                                                placeholder={t('Common.precio')}
                                                options={[
                                                    { value: '1', label: t('Price.0-2.5') },
                                                    { value: '2', label: t('Price.2.5-4') },
                                                    { value: '3', label: t('Price.4-6') },
                                                    { value: '4', label: t('Price.6+') },
                                                ]}
                                                onChange={(price) => setPropertyPrice(price.value)}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: IndicatorsContainer }}
                                            />
                                            <Select
                                                placeholder={t('Common.recamaras')}
                                                options={[
                                                    { value: '1', label: '1' },
                                                    { value: '2', label: '2' },
                                                    { value: '3', label: '3' },
                                                    { value: '4', label: '4' },
                                                    { value: '5', label: '5+' },
                                                ]}
                                                onChange={(type) => setPropertyBedrooms(type.value)}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: IndicatorsContainer }}
                                            />
                                        </div>
                                    </div>
                                    <div className='center'>
                                        <button className="btn btn-login uppercase"
                                            style={{ fontSize: '15px', padding: '11px 50px' }}
                                            disabled={!operationType || !propertyType || !location}
                                            onClick={searchDevelopments}>
                                            {t('Buttons.buscar')}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='mobile-display'>
                                <div className='search-box'>
                                    <div className='group-box'>
                                        <div className='city'>
                                            {/* <AsyncSelect
                                                placeholder='Ciudad, delegación ó colonia'
                                                loadOptions={loadCityOptions}
                                                onChange={(location) => {
                                                    setCity(location.value.city ? location.value.city : location.value.state)
                                                }}
                                                styles={customStyles}
                                                components={{ IndicatorsContainer: SearchIndicatorsContainer }}
                                            /> */}
                                            <Select
                                                placeholder={t('Common.ubicacion')}
                                                options={locationOptions}
                                                onChange={(type) => setLocation(type.value)}
                                                styles={customStylesMobile}
                                                components={{ IndicatorsContainer: IndicatorsContainer }}
                                            />
                                        </div>
                                        <div className='type' style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
                                            <Select
                                                placeholder={t('Common.tipo')}
                                                options={propertyTypeOptions}
                                                onChange={(type) => setPropertyType(type.value)}
                                                styles={customStylesMobile}
                                                components={{ IndicatorsContainer }}
                                            />
                                            <Select
                                                placeholder={t('Common.recamaras')}
                                                options={[
                                                    { value: '1', label: '1' },
                                                    { value: '2', label: '2' },
                                                    { value: '3', label: '3' },
                                                    { value: '4', label: '4' },
                                                    { value: '5', label: '5+' },
                                                ]}
                                                onChange={(type) => setPropertyBedrooms(type.value)}
                                                styles={customStylesMobile}
                                                components={{ IndicatorsContainer }}
                                            />
                                        </div>
                                        <div className='operationType'>
                                            <Select
                                                placeholder={t('Common.tipoOperacion')}
                                                options={operationTypeOptions}
                                                onChange={(type) => setOperationType(type.value)}
                                                styles={customStylesMobile}
                                                components={{ IndicatorsContainer }}
                                            />
                                        </div>
                                    </div>
                                    <div className='center'>
                                        <button className="btn btn-login uppercase"
                                            style={{ fontSize: '15px', padding: '11px 50px' }}
                                            disabled={!operationType || !propertyType || !location}
                                            onClick={searchDevelopments}>
                                            {t('Buttons.buscar')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home-banner-action">
                            <video className='home-banner-video' controls="false" preload='auto' autoPlay loop muted playsInline>
                                <source src="https://monplaces.blob.core.windows.net/assets/monplaces.mp4" type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </div>

                <div style={{ textAlign: 'center', margin: '20px auto' }}>
                    <h2 style={{ margin: '20px auto' }}>
                        <strong>{t('Home.reservaTuPropiedad')}</strong>&nbsp;{t('Home.reservaTuPropiedadBold')}&nbsp;<span class="highlight">{t('Home.reservaTuPropiedadHighlight')}</span>
                    </h2>
                    <Link className="btn btn-action uppercase" onClick={eventTrack.bind(this, "Home Screen", "Reserve Link", "Link")} to={`/developments`}>{t('Buttons.reservar')}</Link>
                </div>

                <div className="center home-developments">
                    <div className='left' style={{ fontSize: '20px', marginBottom: '20px' }}>
                        <p>{t('Home.monplacesSelections')}</p>
                    </div>
                    {loading && <div>{t('Common.loading')}</div>}
                    {error && <div>Error! {error.message}</div>}
                    {groups &&
                        <div className="cards">
                            {groups.sort((a, b) => 0.5 - Math.random()).slice(0, 5).map(group => (
                                group.is_published ?
                                    <div className="card-item" key={group.id} to={`/developments/${group.address.location}/${group.name}`}>
                                        <ImageGallerySimple
                                            items={
                                                [
                                                    group.main_image,
                                                    ...group.photo_image,
                                                    ...group.amenities_image
                                                ]
                                            }
                                            handleClick={() => props.history.push(`/developments/${group.address.location}/${group.name}`)}
                                        />
                                        <Link key={group.id} to={`/developments/${group.address.location}/${group.name}`}>
                                            <div className='info'>
                                                <div>
                                                    {[...new Set(group.unitTypes.map(unitTypes => unitTypes.type))].slice(0, 1).map(type => (
                                                        <label className="badge" key={type}>{type}</label>
                                                    ))}
                                                    {[...new Set(group.units.map(unit => unit.operationType))].slice(0, 1).map(operationType => (
                                                        <label className="badge" key={operationType}>{operationType}</label>
                                                    ))}
                                                </div>
                                                <div>
                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 80px', columnGap: '10px', alignItems: 'center' }}>
                                                        <div>
                                                            <p className="title"> {group.display_name}&nbsp; </p>
                                                        </div>
                                                        <div>
                                                            <img style={{ width: '80px' }} src={group.badge_image_url} alt="badge" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <p className="subtitle">
                                                            {t('Common.precioInicial', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency) })}
                                                        </p>
                                                        <p className='prefix-icon icon-land-size'>
                                                            {t('Common.tamano', { minSize: minValue(group.units, 'size', true), maxSize: maxValue(group.units, 'size', true) })}
                                                        </p>
                                                        <p className='prefix-icon icon-location'>
                                                            {concatAddress(group.address)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    :
                                    group.sold_out ?
                                        <div className="card-item sold-out" key={group.id} to={`/developments`}>
                                            <ImageGallerySimple
                                                items={[group.main_image]}
                                                handleClick={() => { }}
                                            />
                                            <div className='info'>
                                                <div className='outer'>
                                                    {[...new Set(group.unitTypes.map(unitTypes => unitTypes.type))].slice(0, 1).map(type => (
                                                        <label className="badge" key={type}>{type}</label>
                                                    ))}
                                                    {[...new Set(group.units.map(unit => unit.operationType))].slice(0, 1).map(operationType => (
                                                        <label className="badge" key={operationType}>{operationType}</label>
                                                    ))}
                                                </div>
                                                <div className='outer'>
                                                    <p className="title middle"> {group.display_name}&nbsp; </p>
                                                </div>
                                                <div className='outer'>
                                                    <div className='middle'>
                                                        <p className="subtitle">
                                                            {t('Common.precioInicial', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency) })}
                                                        </p>
                                                        <p className='prefix-icon icon-land-size'>
                                                            {t('Common.tamano', { minSize: minValue(group.units, 'size', true), maxSize: maxValue(group.units, 'size', true) })}
                                                        </p>
                                                        <p className='prefix-icon icon-location'>{concatAddress(group.address)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bg-text'>
                                                <div>
                                                    <p className="title">
                                                        {t('DevelopmentList.proyectoVendido')}
                                                    </p>
                                                    <Link className="btn btn-action uppercase" style={{ margin: '20px auto' }} to={`/developments`}>
                                                        {t('Home.explorarMas')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="card-item coming-soon" key={group.id} to={`/developments/${group.address.location}/${group.name}`}>
                                            <ImageGallerySimple
                                                items={[group.main_image]}
                                                handleClick={() => { }}
                                            />
                                            <div className='info'>
                                                <div className='outer'>
                                                    {[...new Set(group.unitTypes.map(unitTypes => unitTypes.type))].slice(0, 1).map(type => (
                                                        <label className="badge" key={type}>{type}</label>
                                                    ))}
                                                    {[...new Set(group.units.map(unit => unit.operationType))].slice(0, 1).map(operationType => (
                                                        <label className="badge" key={operationType}>{operationType}</label>
                                                    ))}
                                                </div>
                                                <div className='outer'>
                                                    <p className="title middle"> {group.display_name}&nbsp; </p>
                                                </div>
                                                <div className='outer'>
                                                    <div className='middle'>
                                                        <p className="subtitle">
                                                            {t('Common.precioInicial', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency) })}
                                                        </p>
                                                        <p className='prefix-icon icon-land-size'>
                                                            {t('Common.tamano', { minSize: minValue(group.units, 'size', true), maxSize: maxValue(group.units, 'size', true) })}
                                                        </p>
                                                        <p className='prefix-icon icon-location'>{concatAddress(group.address)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bg-text'>
                                                <div>
                                                    <p>
                                                        {t('DevelopmentList.desdeEnUbicacion', { price: currencyFormat(minValue(group.units, 'price'), group.units[0].currency), city: group.address.city, state: group.address.state })}
                                                    </p>
                                                    <p className="title">
                                                        {t('DevelopmentList.proximamente')}
                                                    </p>
                                                    <a className="btn btn-action uppercase" style={{ marginBottom: '20px' }}
                                                        href={group.landing_page_url ? group.landing_page_url : `https://wa.me/523318904974?text=Hola Monplaces, quiero recibir información de ${group.display_name}...`}
                                                        target='_blank' rel='noopener noreferrer'>
                                                        {t('DevelopmentList.registrate')}
                                                    </a>
                                                    <p>
                                                        {t('DevelopmentList.listaCero')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                            ))}
                        </div>}
                    <Link className="btn btn-action uppercase" style={{ margin: '20px auto' }} to={`/developments`}>
                        {t('Home.explorarMas')}
                    </Link>
                </div>
                <div className="home-steps">
                    <div className="row center">
                        <div>
                            <h2>{t('Home.comoFunciona')}</h2>
                        </div>
                        <div className='home-steps-content'>
                            <div className='home-steps-item'>
                                <div className='home-steps-item-number'>
                                    <p className="title">1.</p>
                                </div>
                                <div className='home-steps-item-text'>
                                    <p className="text">{t('Home.comoFuncionaPaso1')}</p>
                                </div>
                            </div>
                            <div className='home-steps-item'>
                                <div className='home-steps-item-number'>
                                    <p className="title">2.</p>
                                </div>
                                <div className='home-steps-item-text'>
                                    <p className="text">{t('Home.comoFuncionaPaso2')}</p>
                                </div>
                            </div>
                            <div className='home-steps-item'>
                                <div className='home-steps-item-number'>
                                    <p className="title">3.</p>
                                </div>
                                <div className='home-steps-item-text'>
                                    <p className="text">{t('Home.comoFuncionaPaso3')}</p>
                                </div>
                            </div>
                            <div className='home-steps-item'>
                                <div className='home-steps-item-number'>
                                    <p className="title">4.</p>
                                </div>
                                <div className='home-steps-item-text'>
                                    <p className="text">{t('Home.comoFuncionaPaso4')}</p>
                                    <p className="text"><small>{t('UnitDetails.reembolsable')}</small></p>
                                </div>
                            </div>
                            <div className='home-steps-item'>
                                <div className='home-steps-item-number'>
                                    <p className="title">5.</p>
                                </div>
                                <div className='home-steps-item-text'>
                                    <p className="text">{t('Home.comoFuncionaPaso5')}</p>
                                </div>
                            </div>
                            <div className='home-steps-item'>
                                <div className='home-steps-item-number'>
                                    <p className="title">6.</p>
                                </div>
                                <div className='home-steps-item-text'>
                                    <p className="text">{t('Home.comoFuncionaPaso6')}</p>
                                </div>
                            </div>
                            <div className='home-steps-item' style={{ gridColumnStart: '2', gridColumnEnd: '3' }}>
                                <div className='home-steps-item-number'>
                                    <p className="title">7.</p>
                                </div>
                                <div className='home-steps-item-text'>
                                    <p className="text">{t('Home.comoFuncionaPaso7')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <h3>{t('Home.hablarConExperto')}</h3>
                        <p style={{ fontSize: '22px', color: '#818383' }}>
                            {t('Home.hablarConExpertoDescripcion')}&nbsp;<a className='highlight underline' target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=523318904974&text=%C2%A1Hola!%20Me%20interesa%20comprar,%20vender%20o%20poner%20en%20renta%20por%20medio%20de%20Monplaces....">{t('Home.aqui')}</a>.
                        </p>
                    </div>
                </div>
                <div className="home-steps-mobile">
                    <div className="row center">
                        <div>
                            <h2>{t('Home.comoFunciona')}</h2>
                        </div>
                        <div className='left' style={{ paddingLeft: '15px' }}>
                            <p className="title">1. {t('Home.comoFuncionaPaso1Brief')}</p>
                            <p className="title">2. {t('Home.comoFuncionaPaso2Brief')}</p>
                            <p className="title">3. {t('Home.comoFuncionaPaso3Brief')}</p>
                            <p className="title highlight">4. {t('Home.comoFuncionaPaso4Brief')}</p>
                            <p className="title">5. {t('Home.comoFuncionaPaso5Brief')}</p>
                            <p className="title">6. {t('Home.comoFuncionaPaso6Brief')}</p>
                            <p className="title">7. {t('Home.comoFuncionaPaso7Brief')}</p>
                        </div>
                        <div className="home-search-content">
                            <p><strong>{t('Home.encuentraTuEspacio')}</strong></p>
                            <Link className="btn btn-login" style={{ margin: '0px auto' }} to={`/search`}>{t('Buttons.buscar')}</Link>
                        </div>
                    </div>
                </div>
                <div className='home-news'>
                    <div className='row'>
                        <News items={[
                            {
                                id: 1,
                                publisher_logo_url: 'https://monplaces.blob.core.windows.net/assets/news-publisher/realestatemarket-logo.webp',
                                publisher_name: 'Real Estate Market',
                                header: 'Lo que debes saber antes de comprar o rentar una propiedad',
                                link: 'https://realestatemarket.com.mx/noticias/mercado-inmobiliario/40247-lo-que-debes-saber-antes-de-comprar-o-rentar-una-propiedad'
                            },
                            {
                                id: 2,
                                publisher_logo_url: 'https://monplaces.blob.core.windows.net/assets/news-publisher/inmobiliare-logo.webp',
                                publisher_name: 'Inmobiliare',
                                header: '¿Cuál es la vivienda que prefieren los mexicanos?',
                                link: 'https://inmobiliare.com/cual-es-la-vivienda-que-prefieren-los-mexicanos/'
                            },
                            {
                                id: 3,
                                publisher_logo_url: 'https://monplaces.blob.core.windows.net/assets/news-publisher/eleconomista-logo.webp',
                                publisher_name: 'El Economista',
                                header: 'Espacios que garanticen la calidad de vida definirán la adquisición de vivienda en los siguientes años: Monplaces',
                                link: 'https://www.eleconomista.com.mx/econohabitat/Espacios-que-garanticen-la-calidad-de-vida-definiran-la-adquisicion-de-vivienda-en-los-siguientes-anos-Monplaces-20221227-0018.html'
                            },
                            {
                                id: 4,
                                publisher_logo_url: 'https://monplaces.blob.core.windows.net/assets/news-publisher/eleconomista-logo.webp',
                                publisher_name: 'El Economista',
                                header: 'Espacios que garanticen la calidad de vida definirán la adquisición de vivienda en los siguientes años: Monplaces',
                                link: 'https://www.eleconomista.com.mx/empresas/Espacios-que-garanticen-la-calidad-de-vida-definiran-la-adquisicion-de-vivienda-en-los-siguientes-anos-Monplaces-20221227-0018.html'
                            },
                            {
                                id: 5,
                                publisher_logo_url: 'https://monplaces.blob.core.windows.net/assets/news-publisher/realestatemarket-logo.webp',
                                publisher_name: 'Real Estate Market & Lifestyle',
                                header: 'Nueva proptech busca elevar la calidad de vida en LATAM',
                                link: 'https://realestatemarket.com.mx/noticias/mercado-inmobiliario/39665-nueva-proptech-busca-elevar-la-calidad-de-vida-en-latam'
                            },
                            {
                                id: 6,
                                publisher_logo_url: 'https://monplaces.blob.core.windows.net/assets/news-publisher/eleconomista-logo.webp',
                                publisher_name: 'El Economista',
                                header: 'Monplaces busca eliminar la idea de "chiquito y caro" en materia de vivienda',
                                link: 'https://www.eleconomista.com.mx/econohabitat/Monplaces-busca-eliminar-la-idea-de-chiquito-y-caro-en-materia-de-vivienda-20221117-0050.html'
                            },
                            {
                                id: 7,
                                publisher_logo_url: 'https://monplaces.blob.core.windows.net/assets/news-publisher/elfinanciero-logo.webp',
                                publisher_name: 'El Financiero',
                                header: 'De Jefe-Aterriza en México proptech para mejorar estilo de vida',
                                link: 'https://www.elfinanciero.com.mx/opinion/de-jefes/2022/11/17/aterriza-en-mexico-proptech-para-mejorar-estilo-de-vida/'
                            }
                        ]} />
                    </div>
                </div>
                <div className="row home-search">
                    <div className="home-search-content">
                        <h1><strong>{t('Home.encuentraTuEspacio')}</strong></h1>
                        <Link className="btn btn-login" style={{ margin: '30px auto 0' }} to={`/search`}>{t('Buttons.buscar')}</Link>
                    </div>
                </div>
                <div className="home-features">
                    <div className="cards-3">
                        <div className="card-item">
                            <p className="title">{t('Home.creditoHipotecario')}</p>
                            <div className='mobile-hide'>
                                <svg width="46.5" height="8.5" viewBox="0 0 46.5 8.5" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.657471" y="0.799438" width="45.9669" height="7.825" rx="3.9125" fill="#BDE1E1"></rect></svg>
                            </div>
                            <div className="text">
                                <p>{t('Home.creditoHipotecarioDescripcion')}</p>
                            </div>
                            <div className="center mobile-hide">
                                <a className='btn btn-action'
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://api.whatsapp.com/send?phone=523318904974&text=%C2%A1Hola!%20Me%20interesa%20conocer%20las%20opciones%20de%20crédito%20hipotecario%20por%20medio%20de%20Monplaces...."
                                    onClick={eventTrack.bind(this, "Home Screen", "Mortage Button", "Link")}>
                                    {t('Home.iniciaProceso')}
                                </a>
                            </div>
                            <div className="left mobile-display">
                                <a className='btn btn-action'
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://api.whatsapp.com/send?phone=523318904974&text=%C2%A1Hola!%20Me%20interesa%20conocer%20las%20opciones%20de%20crédito%20hipotecario%20por%20medio%20de%20Monplaces...."
                                    onClick={eventTrack.bind(this, "Home Screen", "Mortage Button", "Link")}>
                                    {t('Home.iniciaProceso')}
                                </a>
                            </div>
                        </div>
                        <div className="card-item">
                            <p className="title">{t('Home.pagoTarjeta')}</p>
                            <div className='mobile-hide'>
                                <svg width="46.5" height="8.5" viewBox="0 0 46.5 8.5" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.657471" y="0.799438" width="45.9669" height="7.825" rx="3.9125" fill="#BDE1E1"></rect></svg>
                            </div>
                            <div className="text">
                                <p>{t('Home.pagoTarjetaDescripcion')}</p>
                            </div>
                        </div>
                        <div className="card-item">
                            <p className="title">{t('Home.personalizado')}</p>
                            <div className='mobile-hide'>
                                <svg width="46.5" height="8.5" viewBox="0 0 46.5 8.5" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.657471" y="0.799438" width="45.9669" height="7.825" rx="3.9125" fill="#BDE1E1"></rect></svg>
                            </div>
                            <div className="text">
                                <p>{t('Home.personalizadoDescripcion')}</p>
                            </div>
                            <div className="center mobile-hide">
                                <a className='btn btn-action' target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=523318904974&text=%C2%A1Hola!%20Me%20interesa%20comprar,%20vender%20o%20poner%20en%20renta%20por%20medio%20de%20Monplaces....">{t('Buttons.contactanos')}</a>
                            </div>
                            <div className="left mobile-display">
                                <a className='btn btn-action' target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=523318904974&text=%C2%A1Hola!%20Me%20interesa%20comprar,%20vender%20o%20poner%20en%20renta%20por%20medio%20de%20Monplaces....">{t('Buttons.contactanos')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='home-newsletter' style={{ display: 'none' }}>
                    <div className="row">
                        <div className='cards-3'>
                            <div className='card-item'>
                                <p>¡Vende o renta tu propiedad con monplaces!</p>
                                <p>Te ayudamos a encontrar al mejor cliente y <strong>facilitamos</strong> el proceso de inicio a fin, haciéndolo también más <strong>seguro</strong>.</p>
                            </div>
                            <div className='card-item'>
                                <svg width="238" height="238" viewBox="0 0 238 238" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M146.019 57.0033C144.991 55.9753 143.596 55.3977 142.142 55.3977C140.688 55.3977 139.294 55.9753 138.266 57.0033L111.364 83.9057L99.7339 72.276C99.225 71.7661 98.6206 71.3615 97.9552 71.0853C97.2899 70.8091 96.5766 70.6667 95.8562 70.6664C95.1358 70.666 94.4224 70.8076 93.7567 71.0831C93.0911 71.3587 92.4863 71.7627 91.9768 72.2721C91.4674 72.7815 91.0634 73.3863 90.7879 74.0519C90.5124 74.7176 90.3708 75.431 90.3711 76.1514C90.3715 76.8718 90.5138 77.5851 90.79 78.2505C91.0662 78.9158 91.4708 79.5202 91.9808 80.0291L107.487 95.5354C107.996 96.0445 108.6 96.4483 109.266 96.7238C109.931 96.9993 110.644 97.1411 111.364 97.1411C112.084 97.1411 112.796 96.9993 113.462 96.7238C114.127 96.4483 114.731 96.0445 115.24 95.5354L146.019 64.7565C147.047 63.7283 147.625 62.3339 147.625 60.8799C147.625 59.4259 147.047 58.0315 146.019 57.0033Z" fill="#4DAAB8" />
                                    <path d="M235.304 90.7852C235.301 90.3811 235.227 89.9805 235.085 89.6022C234.719 88.2563 233.889 87.083 232.742 86.2898L199.395 63.1073V17.7798C199.395 16.1978 198.766 14.6806 197.647 13.5619C196.529 12.4432 195.012 11.8148 193.43 11.8147H44.5704C42.9884 11.8148 41.4712 12.4432 40.3525 13.5619C39.2338 14.6806 38.6054 16.1978 38.6053 17.7798V62.8116L5.09421 85.9758C4.31206 86.516 3.67085 87.2359 3.22434 88.0751C2.77783 88.9143 2.53904 89.8484 2.52799 90.7989C2.52251 90.8335 2.51948 90.8685 2.51892 90.9035L2.69636 220.229C2.69657 221.012 2.85107 221.787 3.15103 222.51C3.45099 223.234 3.89053 223.89 4.44452 224.444C4.99851 224.997 5.65609 225.435 6.37966 225.734C7.10324 226.033 7.87862 226.186 8.66148 226.185H8.67055L229.52 225.885C231.103 225.883 232.62 225.252 233.737 224.132C234.855 223.012 235.482 221.493 235.481 219.911L235.304 90.7852ZM38.6053 104.453L18.9764 90.8853L38.6053 77.3171V104.453ZM183.365 110.296L141.623 139.321L125.284 126.644C125.188 126.566 125.087 126.493 124.983 126.426C123.22 125.153 121.093 124.485 118.92 124.52C116.746 124.556 114.642 125.293 112.921 126.621L96.4136 139.43L54.6351 110.551V27.84H183.365V110.296ZM199.395 104.139V77.6401L218.459 90.8853L199.395 104.139Z" fill="#344D5B" />
                                </svg>
                                <p>Suscríbete a nuestro Newsletter y recibe información relevante del sector, ofertas y oportunidades de inversión.</p>
                                <input placeholder='correo@ejemplo.com'></input>
                            </div>
                            <div className='card-item'>
                                <p>Contáctanos</p>
                                <a href='mailto:customer@monplaces.com' target='_blank' rel='noopener noreferrer' className='btn btn-secundary' style={{ display: 'block', width: '100%', marginTop: '10px' }}>customer@monplaces.com</a>
                                <a href={`https://wa.me/523318904974?text=Hola Monplaces....`} target='_blank' rel='noopener noreferrer' className='btn btn-secundary' style={{ display: 'block', width: '100%', marginTop: '10px' }}>+52 33 1890 4974</a>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className='home-customers' style={{ display: 'none' }}>
                    <div className="row">
                        <h1>Nuestros clientes</h1>
                        <div className="cards-3">
                            <div className="card-item">
                                <p>30 diciembre 2021</p>
                                <p>Encontré exactament lo que buscaba y el proceso fue muy sencillo</p>
                                <hr />
                                <div>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                </div>
                                <p>Alejandra Sánchez</p>
                            </div>
                            <div className="card-item">
                                <p>30 diciembre 2021</p>
                                <p>Además de encontrar la propiedad que quería, me ayudaron a tramitar mi crédito hipotecario, fácil y rápido.</p>
                                <hr />
                                <div>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                </div>
                                <p>Octavio Medina</p>
                            </div>
                            <div className="card-item">
                                <p>30 diciembre 2021</p>
                                <p>Me gustó mucho que no tuviera que imprimir toda mi documentación ni sacar copias, solamente cargarla en la página.</p>
                                <hr />
                                <div>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                    <img src="/images/star.svg" alt="Score"></img>
                                </div>
                                <p>Jesús Gutiérrez</p>
                            </div>
                        </div>
                        <button className="btn btn-action" onClick={() => auth.login()}>Registrate</button>
                    </div>
                </div> */}
                <div className='home-footer'>
                    <div className='row'>
                        <div className='addresses'>
                            <div>
                                <p className="prefix-icon icon-location bold">Ciudad de México</p>
                                <p className='multiline indent-left-30'>
                                    <span>Torre Lexus Santa Fe, oficina 501</span>
                                    <span>Av.Santa Fe, Santa Fe</span>
                                    <span>Paseo de las Lomas</span>
                                    <span>C.P. 01330</span>
                                    <span>Álvaro Obregón, Ciudad de México</span>
                                </p>
                                <p className="prefix-icon icon-whatsapp indent-left-30"><a href='https://wa.me/523318904974'>&nbsp;+52 33 1890 4974</a></p>
                            </div>
                            <div>
                                <p className="prefix-icon icon-location bold">Guadalajara</p>
                                <p className='multiline indent-left-30'>
                                    <span>Camino Real a Colima,</span>
                                    <span>Sorrento Residencial</span>
                                    <span>C.P. 44639</span>
                                    <span>Tlajomulco de Zuñiga, Jal.</span>
                                    <span>&nbsp;</span>
                                </p>
                                <p className="prefix-icon icon-whatsapp indent-left-30"><a href='https://wa.me/523318904974'>&nbsp;+52 33 1890 4974</a></p>
                            </div>
                        </div>
                        <div className='social-links'>
                            <a href="https://www.facebook.com/monplaces" target='_blank' rel='noopener noreferrer'><img src="/images/facebook.svg" alt="Facebook"></img></a>
                            <a href="https://twitter.com/monplaces" target='_blank' rel='noopener noreferrer'><img src="/images/twitter.svg" alt="Twitter"></img></a>
                            <a href="https://www.instagram.com/monplaces_latam/" target='_blank' rel='noopener noreferrer'><img src="/images/instagram.svg" alt="Instagram"></img></a>
                        </div>
                        <div className='center'>
                            <a href="mailto:customer@monplaces.com">customer@monplaces.com</a>&nbsp;--&nbsp;
                            <Link to="/privacy">{t('Navigation.privacidad')}</Link>&nbsp;--&nbsp;
                            <Link to="/terms">{t('Navigation.terminos')}</Link>&nbsp;--&nbsp;
                            {/* <a href="#">Cookies</a> */}
                        </div>
                        <div className='center' style={{ color: '#BEC2C2', marginTop: '20px', fontSize: '12px' }}>
                            <p style={{ margin: '0' }}>Monplaces © {/\d{4}/.exec(Date())[0]}</p>
                            <p style={{ margin: '0' }}>{t('Copy.copyright')}</p>
                        </div>
                        <div className='footer-logo'>
                            <img src="/isotipo.webp" alt="Monplaces"></img>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default withRouter(Home);