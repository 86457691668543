export function currencyFormat(num, currencyFormat = 'GTQ') {
    return num ? 'Q' + Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' ' + currencyFormat.toUpperCase() : 'Q -'
}

export function concatAddress(address) {
    if (!address) return '';

    let items = [];
    if (address.line_1) items.push(address.line_1);
    if (address.line_2) items.push(address.line_2);
    if (address.city) items.push(address.city);
    if (address.state) items.push(address.state);
    if (address.country) items.push(address.country);
    if (address.zip_code) items.push(address.zip_code);

    return items.join(", ");
}

export function minValue(items, name, trunc = false) {
    if (!items || items.length === 0) {
        return 0;
    }

    var value = Math.min.apply(Math, items.map(i => trunc ? Math.trunc(i[name]) : i[name]));

    return value ? value : 0;
}

export function maxValue(items, name, trunc = false) {
    if (!items || items.length === 0) {
        return 0;
    }

    var value = Math.max.apply(Math, items.map(i => trunc ? Math.trunc(i[name]) : i[name]));

    return value ? value : 0;
}

export function toKebab(text) {
    // Lowercase, remove accents and replace whitespaces with dash (-)
    // return text ? text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, "-").toLowerCase() : '';
    return text ? text.replace(/\s+/g, "-").toLowerCase() : '';
}

export function dateStringFormat(dateString) {
    var date = new Date(`${dateString}T00:00:00`)

    return (date > new Date()) ? date.toLocaleDateString('es-MX', { year: 'numeric', month: 'long' }) : 'Inmediata'
}

export function getAmenityIcon(amenity) {
    let amenities = [];
    amenities['Acceso controlado'] = 'https://monplaces.blob.core.windows.net/assets/icons/acceso_controlado_seguridad.svg';
    amenities['Alberca'] = 'https://monplaces.blob.core.windows.net/assets/icons/alberca.svg';
    amenities['Área / espacio para trabajar'] = 'https://monplaces.blob.core.windows.net/assets/icons/coworking.svg';
    amenities['Área Comercial'] = 'https://monplaces.blob.core.windows.net/assets/icons/area_comercial.svg';
    amenities['Área de hidromasaje'] = 'https://monplaces.blob.core.windows.net/assets/icons/hidromasaje_jacuzzi.svg';
    amenities['Área de yoga'] = 'https://monplaces.blob.core.windows.net/assets/icons/yoga.svg';
    amenities['Áreas recreativas'] = 'https://monplaces.blob.core.windows.net/assets/icons/parque.svg';
    amenities['Áreas verdes'] = 'https://monplaces.blob.core.windows.net/assets/icons/parque.svg';
    amenities['Asadores'] = 'https://monplaces.blob.core.windows.net/assets/icons/zona_bbq.svg';
    amenities['Asoleaderos secos y húmedos'] = 'https://monplaces.blob.core.windows.net/assets/icons/sunbed.svg';
    amenities['Baños'] = 'https://monplaces.blob.core.windows.net/assets/icons/banos.svg';
    amenities['BBQ center'] = 'https://monplaces.blob.core.windows.net/assets/icons/zona_bbq.svg';
    amenities['BBQ zone'] = 'https://monplaces.blob.core.windows.net/assets/icons/zona_bbq.svg';
    amenities['Bike rack'] = 'https://monplaces.blob.core.windows.net/assets/icons/bike.svg';
    amenities['Bodegas individuales'] = 'https://monplaces.blob.core.windows.net/assets/icons/bodega.svg';
    amenities['Botadero / Puerto seco'] = 'https://monplaces.blob.core.windows.net/assets/icons/barco.svg';
    amenities['Business center'] = 'https://monplaces.blob.core.windows.net/assets/icons/coworking.svg';
    amenities['Cancha de pádel'] = 'https://monplaces.blob.core.windows.net/assets/icons/tenis/padel.svg';
    amenities['Casa club'] = 'https://monplaces.blob.core.windows.net/assets/icons/casaclub.svg';
    amenities['Caseta de seguridad blindada'] = 'https://monplaces.blob.core.windows.net/assets/icons/acceso_controlado_seguridad.svg';
    amenities['Caseta de vigilancia'] = 'https://monplaces.blob.core.windows.net/assets/icons/acceso_controlado_seguridad.svg';
    amenities['CCTV'] = 'https://monplaces.blob.core.windows.net/assets/icons/CCTV.svg';
    amenities['Ciclopuertos'] = 'https://monplaces.blob.core.windows.net/assets/icons/bike.svg';
    amenities['Cine al aire libre'] = 'https://monplaces.blob.core.windows.net/assets/icons/cinema.svg';
    amenities['Control de acceso'] = 'https://monplaces.blob.core.windows.net/assets/icons/acceso_controlado_seguridad.svg';
    amenities['Coworking'] = 'https://monplaces.blob.core.windows.net/assets/icons/coworking.svg';
    amenities['Elevador'] = 'https://monplaces.blob.core.windows.net/assets/icons/elevador.svg';
    amenities['Estacionamiento (valet parking)'] = 'https://monplaces.blob.core.windows.net/assets/icons/estacionamiento.svg';
    amenities['Estacionamiento techado'] = 'https://monplaces.blob.core.windows.net/assets/icons/estacionamiento.svg';
    amenities['Fire Pit'] = 'https://monplaces.blob.core.windows.net/assets/icons/fire_pit.svg';
    amenities['Fogatero'] = 'https://monplaces.blob.core.windows.net/assets/icons/fire_pit.svg';
    amenities['Gimnasio'] = 'https://monplaces.blob.core.windows.net/assets/icons/gym.svg';
    amenities['Gym'] = 'https://monplaces.blob.core.windows.net/assets/icons/gym.svg';
    amenities['High lobby'] = 'https://monplaces.blob.core.windows.net/assets/icons/lobby.svg';
    amenities['Huertos Urbanos'] = 'https://monplaces.blob.core.windows.net/assets/icons/huerto.svg';
    amenities['Jardineras'] = 'https://monplaces.blob.core.windows.net/assets/icons/planta.svg';
    amenities['Juegos para niños'] = 'https://monplaces.blob.core.windows.net/assets/icons/kids.svg';
    amenities['Jungle Walking'] = 'https://monplaces.blob.core.windows.net/assets/icons/nature.svg';
    amenities['Laundry room'] = 'https://monplaces.blob.core.windows.net/assets/icons/laundry.svg';
    amenities['Lobby'] = 'https://monplaces.blob.core.windows.net/assets/icons/lobby.svg';
    amenities['Área lounge'] = 'https://monplaces.blob.core.windows.net/assets/icons/drink.svg';
    amenities['Lounge'] = 'https://monplaces.blob.core.windows.net/assets/icons/drink.svg';
    amenities['Motor Lobby'] = 'https://monplaces.blob.core.windows.net/assets/icons/estacionamiento.svg';
    amenities['Parque'] = 'https://monplaces.blob.core.windows.net/assets/icons/parque.svg';
    amenities['Parque central'] = 'https://monplaces.blob.core.windows.net/assets/icons/parque.svg';
    amenities['Parque infantil'] = 'https://monplaces.blob.core.windows.net/assets/icons/parque.svg';
    amenities['Parque lineal'] = 'https://monplaces.blob.core.windows.net/assets/icons/parque.svg';
    amenities['Pet Friendly'] = 'https://monplaces.blob.core.windows.net/assets/icons/pet.svg';
    amenities['Pet park'] = 'https://monplaces.blob.core.windows.net/assets/icons/pet.svg';
    amenities['Ready to rent'] = 'https://monplaces.blob.core.windows.net/assets/icons/key.svg';
    amenities['Restaurante'] = 'https://monplaces.blob.core.windows.net/assets/icons/restaurant.svg';
    amenities['Roof garden'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Rooftop'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Rooftop Garden'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Rooftop lounge'] = 'https://monplaces.blob.core.windows.net/assets/icons/drink.svg';
    amenities['Sala de juntas'] = 'https://monplaces.blob.core.windows.net/assets/icons/coworking.svg';
    amenities['Salón de juegos'] = 'https://monplaces.blob.core.windows.net/assets/icons/game_room.svg';
    amenities['Salón de juegos / Game room'] = 'https://monplaces.blob.core.windows.net/assets/icons/game_room.svg';
    amenities['Salón de usos múltiples'] = 'https://monplaces.blob.core.windows.net/assets/icons/game_room.svg';
    amenities['Seguridad'] = 'https://monplaces.blob.core.windows.net/assets/icons/acceso_controlado_seguridad.svg';
    amenities['Servicio de limpieza (costo adicional)'] = 'https://monplaces.blob.core.windows.net/assets/icons/cleaning.svg';
    amenities['Sky bar'] = 'https://monplaces.blob.core.windows.net/assets/icons/drink.svg';
    amenities['Smart Doorlock'] = 'https://monplaces.blob.core.windows.net/assets/icons/smart_doorlock.svg';
    amenities['Smart Home (costo adicional)'] = 'https://monplaces.blob.core.windows.net/assets/icons/smart_home.svg';
    amenities['Tech Closet'] = 'https://monplaces.blob.core.windows.net/assets/icons/tech_closet.svg';
    amenities['Terraza'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Palapa'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Terrazas'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Terraza de lectura'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Terraza de usos múltiples'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Terraza para eventos'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Tool library'] = 'https://monplaces.blob.core.windows.net/assets/icons/tool.svg';
    amenities['Vestidores'] = 'https://monplaces.blob.core.windows.net/assets/icons/vestidores.svg';
    amenities['Workout zone'] = 'https://monplaces.blob.core.windows.net/assets/icons/gym.svg';
    amenities['Yoga center'] = 'https://monplaces.blob.core.windows.net/assets/icons/yoga.svg';
    amenities['Zen Terrace'] = 'https://monplaces.blob.core.windows.net/assets/icons/zen.svg';
    amenities['Zona BBQ'] = 'https://monplaces.blob.core.windows.net/assets/icons/zona_bbq.svg';
    amenities['Zona de yoga'] = 'https://monplaces.blob.core.windows.net/assets/icons/yoga.svg';
    amenities['Zona pet'] = 'https://monplaces.blob.core.windows.net/assets/icons/pet.svg';
    amenities['Doble infinity pool'] = 'https://monplaces.blob.core.windows.net/assets/icons/alberca.svg';
    amenities['Alberca con carril de nado'] = 'https://monplaces.blob.core.windows.net/assets/icons/alberca.svg';
    amenities['Chapoteadero'] = 'https://monplaces.blob.core.windows.net/assets/icons/alberca.svg';
    amenities['Mirador'] = 'https://monplaces.blob.core.windows.net/assets/icons/binoculares.svg';
    amenities['Jacuzzi'] = 'https://monplaces.blob.core.windows.net/assets/icons/hidromasaje_jacuzzi.svg';
    amenities['Baños con cambiadores'] = 'https://monplaces.blob.core.windows.net/assets/icons/banos.svg';
    amenities['Sanitarios'] = 'https://monplaces.blob.core.windows.net/assets/icons/banos.svg';
    amenities['Botadero / Puerto seco'] = 'https://monplaces.blob.core.windows.net/assets/icons/barco.svg';
    amenities['Cancha de pádel'] = 'https://monplaces.blob.core.windows.net/assets/icons/tenis_padel.svg';
    amenities['Fire pit area'] = 'https://monplaces.blob.core.windows.net/assets/icons/fire_pit.svg';
    amenities['Crossfit area'] = 'https://monplaces.blob.core.windows.net/assets/icons/gym.svg';
    amenities['Sky gym'] = 'https://monplaces.blob.core.windows.net/assets/icons/gym.svg';
    amenities['Huerto Urbano'] = 'https://monplaces.blob.core.windows.net/assets/icons/huerto.svg';
    amenities['Ludoteca'] = 'https://monplaces.blob.core.windows.net/assets/icons/kids.svg';
    amenities['Valet Parking'] = 'https://monplaces.blob.core.windows.net/assets/icons/estacionamiento.svg';
    amenities['Oficina'] = 'https://monplaces.blob.core.windows.net/assets/icons/coworking.svg';
    amenities['Sala de lectura'] = 'https://monplaces.blob.core.windows.net/assets/icons/libro.svg';
    amenities['Sala de juegos'] = 'https://monplaces.blob.core.windows.net/assets/icons/game_room.svg';
    amenities['Bar'] = 'https://monplaces.blob.core.windows.net/assets/icons/drink.svg';
    amenities['Salón ingles'] = 'https://monplaces.blob.core.windows.net/assets/icons/drink.svg';
    amenities['Coffe - bar'] = 'https://monplaces.blob.core.windows.net/assets/icons/drink.svg';
    amenities['Smart Doorlock'] = 'https://monplaces.blob.core.windows.net/assets/icons/smart_doorlock.svg';
    amenities['Terraza'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Terraza abierta'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Terraza cerrada'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Sala multiusos'] = 'https://monplaces.blob.core.windows.net/assets/icons/terrace.svg';
    amenities['Spa'] = 'https://monplaces.blob.core.windows.net/assets/icons/zen.svg';

    return amenities[amenity];
}