import React, { useEffect, useState } from 'react';
import { useQuery, gql } from "@apollo/client";
import { withRouter, Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Select, { components } from 'react-select';
import Map from '../../components/Map';
import { currencyFormat, concatAddress, minValue, dateStringFormat, getAmenityIcon } from '../../utils.js';

import ImageGalleryGrid from '../../components/ImageGalleryGrid/ImageGalleryGrid';
import ImageGallerySimple from '../../components/ImageGallerySimple/ImageGallerySimple';
import AuthLinkDownload from '../../components/AuthLinkDownload/AuthLinkDownload';

import '../../App.css';
import './DetailDevelopment.css';

const DEVELOPMENT = gql`
  query GetDevelopmentById($operationType: String, $propertyType: String, $city: String, $name: String, $groupId: ID!) {
    group(operation_type: $operationType, property_type: $propertyType, city: $city, name: $name, id: $groupId) {
      id
      name
      display_name
      delivery_date
      brochure_url
      price_list_url
      badge_image_url
      gallery_urls {
        url
        thumbnailUrl
        type
        tag
      }
      amenities
      address {
        line_1
        line_2
        city
        state
        country
        zip_code
        location
      }
      unitTypes {
        id
        type
        name
        display_name
        gallery_urls {
            url
            thumbnailUrl
            type
            tag
        }
        units {
          bedrooms
          bathrooms
          parking_spots
          price
          currency
          size
          floor
          status
          operationType
        }
      }
      developers {
        display_name
      }
    }
  }
`

const customStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: '#465C66',
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? 'rgba(41, 171, 184, 0.18)'
                : isFocused
                    ? 'rgba(41, 171, 184, 0.18)'
                    : undefined,
        padding: '5px',
    }),
    control: (styles) => ({
        ...styles,
        border: 'none',
        minHeight: '30px',
        marginBottom: '5px',
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#000'
    })
}

const CaretDownIcon = () => {
    return <svg width="30" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.86873 4.79195C1.56248 5.11014 1.56248 5.62312 1.86873 5.94131L7.06247 11.3374C7.30622 11.5907 7.69997 11.5907 7.94372 11.3374L13.1375 5.94131C13.4437 5.62312 13.4437 5.11014 13.1375 4.79195C12.8174 4.78546 12.7182 4.78546 12.0312 4.79195L7.46024 4.79195L2.96873 4.78546C2.30151 4.79195 2.20231 4.79195 1.86873 4.79195Z" fill="#F74750" />
    </svg>
}

const IndicatorsContainer = props => {
    return (
        <components.IndicatorsContainer {...props}>
            <CaretDownIcon />
        </components.IndicatorsContainer>
    );
};

let floorOptions = [];

const DetailDevelopment = (props) => {
    const { t } = useTranslation();

    const operationType = props.match.params.operation ? props.match.params.operation.toLowerCase() : "";
    const propertyType = props.match.params.type ? props.match.params.type.toLowerCase() : "";
    const location = props.match.params.location ? props.match.params.location.toLowerCase() : "";
    const name = props.match.params.development ? props.match.params.development.toLowerCase() : "";
    const groupId = props.match.params.developmentId ? Number(props.match.params.developmentId) : 0;

    const { loading, data, error } = useQuery(DEVELOPMENT, {
        variables: {
            operationType: operationType,
            propertyType: propertyType,
            city: location,
            name: name,
            groupId: groupId
        }
    })

    const [group, setGroup] = useState(null);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [zoom, setZoom] = useState(4);
    const [bedrooms, setBedrooms] = useState(null)
    const [floor, setFloor] = useState(null)

    const handleAddressChange = async (address) => {
        fetch(`https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=l-zc7x-OI-k5ZMh3lWXjXyT2jAzH_OS6lqQZwAEXj7Y`, { method: "GET" })
            .then(response => response.json())
            .then(data => {
                if (data.items && data.items.length > 0) {
                    setLat(data.items[0].position.lat);
                    setLng(data.items[0].position.lng);
                    setZoom(13);
                }
                else {
                    setLat(20.69408);
                    setLng(-103.39115);
                    setZoom(13);
                }
            });
    }

    useEffect(() => {
        if (data) {
            const groupFiltered = {
                id: data.group.id,
                name: data.group.name,
                display_name: data.group.display_name,
                delivery_date: data.group.delivery_date,
                display_delivery_date: dateStringFormat(data.group.delivery_date),
                badge_image_url: data.group.badge_image_url,
                main_image: data.group.gallery_urls.filter(i => i.tag === 'main')[0],
                photo_image: data.group.gallery_urls.filter(i => i.tag === 'photo'),
                amenities_image: data.group.gallery_urls.filter(i => i.tag === 'amenity'),
                amenities: data.group.amenities,
                brochure_url: data.group.brochure_url,
                price_list_url: data.group.price_list_url,
                address: data.group.address,
                displayAddress: concatAddress(data.group.address),
                unitTypes: data.group.unitTypes.filter(unitType => !propertyType || unitType.type.toLowerCase() === propertyType).map(unitType => {
                    return {
                        id: unitType.id,
                        type: unitType.type,
                        name: unitType.name,
                        display_name: unitType.display_name,
                        main_image: unitType.gallery_urls.filter(i => i.tag === 'main')[0],
                        layout_image: unitType.gallery_urls.filter(i => i.tag === 'layout')[0],
                        units: unitType.units.filter(unit =>
                            unit.status.toLowerCase() === 'disponible'
                            && (!operationType || (operationType === 'compra' && (unit.operationType.toLowerCase() === 'venta' || unit.operationType.toLowerCase() === 'preventa')) || unit.operationType.toLowerCase() === operationType)
                            && (!bedrooms || unit.bedrooms >= bedrooms)
                            && (!floor || unit.floor === floor)
                        )
                    }
                }),
                units: [].concat(...data.group.unitTypes.filter(unitType => !propertyType || unitType.type.toLowerCase() === propertyType).map(unitType => {
                    return unitType.units.filter(unit =>
                        unit.status.toLowerCase() === 'disponible'
                        && (!operationType || (operationType === 'compra' && (unit.operationType.toLowerCase() === 'venta' || unit.operationType.toLowerCase() === 'preventa')) || unit.operationType.toLowerCase() === operationType)
                        && (!bedrooms || unit.bedrooms >= bedrooms)
                        && (!floor || unit.floor === floor)
                    )
                })),
                maxFloor: Math.max(...[].concat(...data.group.unitTypes.filter(unitType => !propertyType || unitType.type.toLowerCase() === propertyType).map(unitType => {
                    return unitType.units.filter(unit =>
                        unit.status.toLowerCase() === 'disponible'
                        && (!operationType || (operationType === 'compra' && (unit.operationType.toLowerCase() === 'venta' || unit.operationType.toLowerCase() === 'preventa')) || unit.operationType.toLowerCase() === operationType)
                    ).map(unit => unit.floor)
                }))),
                developers: data.group.developers.map(d => d.display_name).join()
            };

            setGroup(groupFiltered);

            handleAddressChange(groupFiltered.displayAddress);

            floorOptions = [
                { value: '', label: 'Seleccione...' },
                { value: '0', label: 'PB' }
            ]
            for (let i = 0; i < groupFiltered.maxFloor; i++) {
                floorOptions.push({ value: `${i + 1}`, label: `${i + 1}` })
            }
        }
    }, [loading, data, operationType, propertyType, bedrooms, floor]);

    if (loading) return <div className="container-fluid"><div className='row'>Loading...</div></div>;
    if (error) return <div className="container-fluid"><div className='row'>Error! {error.message}</div></div>;

    return (
        <div className="container-fluid">
            <div style={{ margin: '0 20px' }}>
                <div className='row development-details-title'>
                    <h3><Link className='prefix-icon icon-arrow-left' to={`/developments`}>Detalles del desarrollo</Link></h3>
                </div>
                {group &&
                    <>
                        <div className='row mobile-hide'>
                            <div style={{ padding: '20px 0px 10px', borderBottom: '1px solid #e2e5e4', margin: '0 30px 10px' }}>
                                <h3>
                                    {group.display_name}
                                    {
                                        group.badge_image_url && <img style={{ width: '80px', marginLeft: '30px' }} src={group.badge_image_url} alt="badge" />
                                    }
                                </h3>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                                    <div className="prefix-icon icon-location">
                                        <p style={{ margin: '0' }}>{group.displayAddress}</p>
                                    </div>
                                    <div>
                                        <p className="prefix-icon icon-calendar">{t('Common.entrega')}: {group.display_delivery_date}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mobile-hide'>
                            <div className='row'>
                                <div style={{ padding: '20px 0px 10px', margin: '0 30px 20px' }}>
                                    <ImageGalleryGrid
                                        mainItem={group.main_image}
                                        items={
                                            [
                                                ...group.photo_image,
                                                ...group.amenities_image
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='mobile-display'>
                            <div className='row' style={{ marginTop: '20px', marginLeft: '-35px', marginRight: '-35px' }}>
                                <div style={{ borderBottom: '1px solid #e2e5e4' }}>
                                    <ImageGallerySimple
                                        items={
                                            [
                                                group.main_image,
                                                ...group.photo_image,
                                                ...group.amenities_image
                                            ]
                                        }
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div style={{ padding: '10px 0 20px', borderBottom: '1px solid #e2e5e4' }}>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 80px', columnGap: '10px', alignItems: 'center' }}>
                                        <div>
                                            <h3>{group.display_name}</h3>
                                        </div>
                                        <div>
                                            {
                                                group.badge_image_url && <img style={{ width: '80px' }} src={group.badge_image_url} alt="badge" />
                                            }
                                        </div>
                                    </div>
                                    <p className="prefix-icon icon-location">{group.displayAddress}</p>
                                    <p className="prefix-icon icon-calendar">{t('Common.entrega')}: {group.display_delivery_date}</p>
                                </div>
                            </div>
                        </div>

                        <div className='development-details'>
                            <div className='info-container'>
                                <div className='links-container'>
                                    {group.price_list_url &&
                                        <AuthLinkDownload
                                            page={'Development Detail Screen'}
                                            action={'Download PriceList Link'}
                                            fileUrl={group.price_list_url}
                                            fileName={'precios.pdf'}
                                            className={'btn btn-secundary'}
                                            style={{ display: "block", marginBottom: "15px", width: "220px" }}>
                                            {t('Common.descargarPrecios')}
                                        </AuthLinkDownload>
                                    }
                                    {group.brochure_url &&
                                        <AuthLinkDownload
                                            page={'Development Detail Screen'}
                                            action={'Download Brochure Link'}
                                            fileUrl={group.brochure_url}
                                            fileName={'brochure.pdf'}
                                            className={'btn btn-secundary'}
                                            style={{ display: "block", width: "220px" }}>
                                            {t('Common.descargarBrochure')}
                                        </AuthLinkDownload>
                                    }
                                </div>

                                <div className='amenities-container'>
                                    <h4>{t('UnitDetails.amenidades')}</h4>
                                    <div className='amenities'>
                                        {group.amenities.map(amenity => (
                                            <div key={amenity}>
                                                <p className='prefix-icon' style={{
                                                    backgroundImage: `url(${getAmenityIcon(amenity)})`,
                                                    backgroundSize: '30px 30px',
                                                    backgroundRepeat: 'no-repeat'
                                                }}>
                                                    {amenity}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className='map-container'>
                                    <h4>Mapa</h4>
                                    <div>
                                        <Map
                                            lat={lat}
                                            lng={lng}
                                            zoom={zoom}
                                        />
                                    </div>
                                    <div className='left' style={{ padding: '10px 0', textDecoration: 'underline' }}>
                                        <a href={`https://maps.google.com/?q=${group.displayAddress}`} target='_blank' rel='noopener noreferrer'>
                                            {group.displayAddress}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className='unit-types-container'>
                                <h4>Tipos de unidades</h4>
                                <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
                                    <Select
                                        placeholder='Dormitorios'
                                        options={[
                                            { value: '', label: t('Common.seleccione') },
                                            { value: '1', label: '1' },
                                            { value: '2', label: '2' },
                                            { value: '3', label: '3' },
                                            { value: '4', label: '4' },
                                            { value: '5', label: '5+' }
                                        ]}
                                        onChange={(type) => setBedrooms(type.value)}
                                        styles={customStyles}
                                        components={{ IndicatorsContainer: IndicatorsContainer }}
                                    />
                                    <Select
                                        placeholder='Nivel'
                                        options={floorOptions}
                                        onChange={(floor) => setFloor(floor.value)}
                                        styles={customStyles}
                                        components={{ IndicatorsContainer: IndicatorsContainer }}
                                    />
                                </div>
                                <div className='unit-types cards-1'>
                                    {group.unitTypes.map(unitType => (
                                        unitType.units.length > 0 &&
                                        <Link className='card-item' key={unitType.id} to={`/developments/${location}/${name}/${unitType.name}`}>
                                            <div className='left-container'>
                                                <div className='title'>
                                                    <p style={{ fontWeight: 'normal' }}>Tipo: {unitType.display_name}</p>
                                                </div>
                                                <div className='image'>
                                                    <img src={unitType.layout_image.url} alt={unitType.display_name} style={{ position: 'center' }}></img>
                                                </div>
                                                <div>
                                                    {unitType.units.filter(function (o) { return o.status === 'Disponible' }).length === 1 &&
                                                        <div>
                                                            <label className="badge mobile-hide">{unitType.units.filter(function (o) { return o.status === 'Disponible' }).length} unidad disponible</label>
                                                            <label className="badge mobile-display">{unitType.units.filter(function (o) { return o.status === 'Disponible' }).length} disponible</label>
                                                        </div>
                                                    }
                                                    {unitType.units.filter(function (o) { return o.status === 'Disponible' }).length !== 1 &&
                                                        <div>
                                                            <label className="badge mobile-hide">{unitType.units.filter(function (o) { return o.status === 'Disponible' }).length} unidades disponibles</label>
                                                            <label className="badge mobile-display">{unitType.units.filter(function (o) { return o.status === 'Disponible' }).length} disponibles</label>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className='right-container info outer'>
                                                <div className='middle'>
                                                    <p className="subtitle">{currencyFormat(minValue(unitType.units, 'price'), unitType.units[0].currency)}</p>
                                                    <p className="prefix-icon icon-land-size">{minValue(unitType.units, 'size')} m2</p>
                                                    <p className="prefix-icon icon-bedroom">{minValue(unitType.units, 'bedrooms')} dorm</p>
                                                    {minValue(unitType.units, 'bathrooms') === 1 &&
                                                        < p className="prefix-icon icon-bathroom" > 1 baño</p>
                                                    }
                                                    {minValue(unitType.units, 'bathrooms') !== 1 &&
                                                        < p className="prefix-icon icon-bathroom" > {minValue(unitType.units, 'bathrooms')} baños</p>
                                                    }
                                                    {minValue(unitType.units, 'parking_spots') === 1 &&
                                                        < p className="prefix-icon icon-car nowrap" > 1 parqueo</p>
                                                    }
                                                    {minValue(unitType.units, 'parking_spots') !== 1 &&
                                                        < p className="prefix-icon icon-car nowrap" > {minValue(unitType.units, 'parking_spots')} parqueos</p>
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                                <small>*Las características de las unidades y el precio puede variar dependiendo del nivel, vista y/u otros factores. Las unidades y el precio están sujetos a confirmar disponibilidad y última lista de precios actualizada con el desarrollador, por lo que pueden modificarse y/o variar de lo aquí representado.</small>

                                <div className='footer-container'>
                                    <a href={`https://wa.me/523318904974?text=Hola Monplaces, estoy interesado en el desarrollo ${group.display_name}`} target='_blank' rel='noopener noreferrer'>
                                        ¿Tienes dudas? Contáctanos por WhatsApp&nbsp;&nbsp;
                                        <img style={{ width: '36px', height: 'auto' }} src='/images/whatsapp.svg' alt='Whatsapp logo'></img>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='row' style={{ paddingBottom: "30px" }}>
                            <h4>Desarrolladora</h4>
                            <span className='prefix-icon icon-developer'>{group.developers}</span>
                        </div>
                    </>
                }
            </div >
        </div >
    );
}

export default withRouter(DetailDevelopment);